import React, { useState } from 'react';
import { Router, Route } from "react-router-dom";
import { Container } from 'reactstrap';
import history from "./history"
import { isIE, isEdge, isMobileOnly, withOrientationChange, isFirefox } from "react-device-detect";


import Home from "./chapters/Home.js";
import Footer from './components/Footer.js'
import Helmet from 'react-helmet';
import FooterSupport from './components/FooterSupport.js'
import Menu from './components/Menu.js'
import TheSecret from "./chapters/TheSecret.js";
import HiddenAway from "./chapters/HiddenAway.js";
import Separation from "./chapters/Separation.js";
import TheSilence from "./chapters/TheSilence.js";
import Healing from "./chapters/Healing.js";
import Epilogue from "./chapters/Epilogue.js"

// stories
import TheSecretStories from "./stories/TheSecretStories.js";
import HiddenAwayStories from "./stories/HiddenAwayStories.js";
import SeparationStories from "./stories/SeparationStories.js";
import TheSilenceStories from "./stories/TheSilenceStories.js";
import HealingStories from "./stories/HealingStories.js";


//other
import About from "./About.js";
import Teachers from "./Teachers.js"
import GetInvolved from "./GetInvolved";
import MakeChange from "./MakeChange";
import Credits from "./Credits.js";
import BackgroundAudio from "./components/BackgroundAudio"
import canAutoPlay from 'can-autoplay';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';


const App = (props) => {
  const [tested, setTested ] = useState(false)
  const [muted, setMuted] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [menuOpen, slidingMenu] = useState(false)
  const { isLandscape, isPortrait } = props

  const [ autoplayWorking, setAutoplayWorking] = useState(undefined)

  setTimeout(() => {
    canAutoPlay
      .video({muted: true})
      .then(({result}) => {
        setAutoplayWorking(result ? true : false)
      })
  }, 500)

  const vidToBeMuted = (item) => {
    // debugger
    if (item === false) {
      setMuted(true)
    } else {
      setMuted(false)
    }
  }

  const vidisPlaying = (item) => {
    if (item === false) {
      setPlaying(false)
    } else {
      setPlaying(true)
    }
  }

  const shutMenu = () => {
    slidingMenu(false)
  }


  const testBrowser = () => {
   let videoTested;
   let audioTested;
  }

  if (!tested ) {

    let videoTested;
    let audioTested;
    let hasBeenTested = false;
    let setValue;
  }

  const turnOffAudio = (item) => {
      setPlaying(!playing)
  }

  const newPageLoad = () => {
      setMuted(false)
      setPlaying(false)
  }

  const toggle = () => slidingMenu(!menuOpen)

  if (autoplayWorking !== undefined) {
    if (isMobileOnly) {
      return (
        <div>
        <div className="mobileBackground"></div>
        <Container className="maxWidth">
          <div className="mobileOverlay">

          <div className="homeLogo">
            <span>mum's the word</span>
            <em>An interactive experience about a nation of secrets</em></div>

            <br/>
              <div className="blurb">is best Viewed on Desktop / Laptop Screens</div>
         </div>


         <FooterSupport />

        </Container>
        </div>
      ) }
    else {
      return (
        <div>

        <Helmet>
          <meta property="og:image:secure_url" content="https://secure.example.com/ogp.jpg" />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="400" />
          <meta property="og:image:height" content="300" />
          <meta property="og:image:alt" content="A shiny red apple with a bite taken out" />
          <meta property="og:image" content={window.location.href+"banner-social.jpg"} />
        </Helmet>

        <Router history={history}>
          <button href="#sidebar" aria-label="open the menu" aria-expanded="false" role="button" id="sidebarIconToggle" onClick={toggle} >
          { menuOpen &&
              <div aria-hidden={'true'}>
                <div className="spinner diagonal part-1"></div>
                <div className="spinner horizontally"></div>
                <div className="spinner diagonal part-2"></div>
              </div>
            }
            { !menuOpen &&
              <div aria-hidden={'true'}>
                <div className="spinner straight part-1"></div>
                <div className="spinner horizontal"></div>
                <div className="spinner straight part-2"></div>
              </div>
            }
          </button>

            <Menu toggle={toggle} isOpen={menuOpen} aria-expanded="true"/>

            <Route exact path="/" render={(props) => (<Home {...props} autoplayWorking={autoplayWorking} vidisPlaying={vidisPlaying}  vidToBeMuted={vidToBeMuted} muted={muted} newPageLoad={newPageLoad} /> )} />
            <Route path="/the-secret"  render={(props) => (<TheSecret {...props} muted={muted} vidisPlaying={vidisPlaying} newPageLoad={newPageLoad} /> )} />
            <Route path="/hidden-away" render={(props) => (<HiddenAway {...props} muted={muted} newPageLoad={newPageLoad} /> )} />
            <Route path="/separation" render={(props) => (<Separation {...props} muted={muted} newPageLoad={newPageLoad} /> )} />
            <Route path="/the-silence" render={(props) => (<TheSilence {...props} muted={muted} newPageLoad={newPageLoad} /> )} />
            <Route path="/healing" render={(props) => (<Healing {...props} muted={muted} newPageLoad={newPageLoad} /> )} />
            <Route path="/epilogue" render={(props) => (<Epilogue {...props} muted={muted} newPageLoad={newPageLoad} /> )} />
            <Route path="/the-secret-stories" render={(props) => (<TheSecretStories {...props} muted={muted} vidisPlaying={vidisPlaying} newPageLoad={newPageLoad} isLandscape={isLandscape} isIE={isIE} isEdge={isEdge} /> )} />
            <Route path="/hidden-away-stories" render={(props) => (<HiddenAwayStories {...props} muted={muted} vidisPlaying={vidisPlaying} newPageLoad={newPageLoad} isLandscape={isLandscape} isIE={isIE} isEdge={isEdge}  /> )} />
            <Route path="/separation-stories" render={(props) => (<SeparationStories {...props} muted={muted} vidisPlaying={vidisPlaying} newPageLoad={newPageLoad} isLandscape={isLandscape} isIE={isIE} isEdge={isEdge}  /> )} />
            <Route path="/the-silence-stories" render={(props) => (<TheSilenceStories {...props} muted={muted} vidisPlaying={vidisPlaying} newPageLoad={newPageLoad} isLandscape={isLandscape} isIE={isIE} isEdge={isEdge} /> )} />
            <Route path="/healing-stories" render={(props) => (<HealingStories {...props} muted={muted} isEdge={isEdge}  vidisPlaying={vidisPlaying} newPageLoad={newPageLoad} isLandscape={isLandscape} isIE={isIE} /> )} />

            <Route path="/credits" component={Credits} />
            <Route path="/about" component={About} />
            <Route path="/make-a-change" render={(props) => (<MakeChange {...props} date={Date.now()} /> )} />
            <Route path="/for-teachers" component={Teachers} />
            <Route path="/get-involved" component={GetInvolved} />


            <Route { ...props } render={ renderProps => (<Footer { ...renderProps } { ...props } muted={muted} playing={playing} vidToBeMuted={vidToBeMuted} turnOffAudio={turnOffAudio} shutMenu={shutMenu} />) } />

            <Route render={(props) => (<BackgroundAudio {...props} muted={muted} playing={playing} /> )} />


          </Router>
        </div>
      )
    }
  } else {
    return null
  }
}

export default withOrientationChange(App);
