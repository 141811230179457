import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import {  Link } from "react-router-dom";
import SocialMediaButtons from './shareButtons.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeOff, faVolumeUp } from '@fortawesome/free-solid-svg-icons'


const Footer = props => {
  const [muted, setMuted] = useState(props.muted)
  const [playing, setPlaying] = useState(props.playing)
  const path = props.location.pathname

  const handleClick = (item) => {
    props.vidToBeMuted(item)
  }

  const shutMenu = () => {
    props.shutMenu();
  }

  useEffect(() => {
    setMuted(props.muted) // 2. props changed? do this
  }, [props]) // 1. if props has changed?, no.


  return (
    <Container fluid className="footer">
      <Row>
        <Col sm="12">
        <div className="">
          <Row>
          <Link to="/" onClick={
              (e, props) => shutMenu()
            } className="footerButton">
            HOME
         </Link>
          <div className="greySlash">|</div>
         <Link to="/about" onClick={
             (e, props) => shutMenu()
           } className="footerButton">
           ABOUT
        </Link>
         <div className="greySlash">|</div>
         <Link to="/credits" onClick={
             (e, props) => shutMenu()
           } className="footerButton">
           CREDITS
        </Link>

         <div className="shareFooter">
          <SocialMediaButtons url="www.mumsthewordthefilm.com" text="Check out this website: www.mumsthewordthefilm.com"/>
          {}
          <div className="greySlash">|</div>
              <React.Fragment>
              {!muted && (
                <div className="muteButton footerButton"  onClick={
                    (e, props) => handleClick(muted)
                  }>
                  <button><FontAwesomeIcon icon={faVolumeUp} /></button>
                </div>
              )}
              {muted && (
                <div className="muteButton footerButton"  onClick={
                    (e, props) => handleClick(muted)
                  }><button><FontAwesomeIcon icon={faVolumeOff} /></button>
                </div>

              )}
              </React.Fragment>




          </div>
          </Row>
        </div>

        </Col>
      </Row>
    </Container>
  )

}

export default Footer
