import React, { useState, useEffect, Fragment } from "react";
import VideoComponent from '../components/VideoComponent'
import { Container } from 'reactstrap';
const Separation = props => {
  const [muted, setMuted] = useState(false)

  const [videoUrl] = useState('https://vimeo.com/361865173')

  const loadStories = () => {
    props.history.push('/separation-stories')
  }

  useEffect(() => {
    setMuted(props.muted) // 2. props changed? do this
  }, [props]) // 1. if props has changed?, no.

  useEffect(() => {
  props.newPageLoad()
  }, [])
  return (
    <Fragment>
      <Container fluid  className="confineWidth no-padding">
      <VideoComponent
        ariaLabel="Chapter Three Video"
        videoUrl={videoUrl}
        play={true}
        imageUrl={"/separation.jpg"}
        controls={true}
        onVideoEndedCallback={loadStories}
        muted={muted} />
        </Container>
    </Fragment>
  )
}

export default Separation;
