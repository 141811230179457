import React, { useState } from 'react';
import SubMenu from './SubMenu';
import { NavLink, Nav } from 'reactstrap';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

const SideBar = props => {
  const [collapseset, makeCollapse] = useState(true)


  const changeCollapse = () => {
      makeCollapse(!collapseset)
  }
  return (
    <div className={classNames('sidebar', {'is-open': props.isOpen})}>
      <div className="sidebarMenuInner">
        <Nav id="sidebar" vertical className="list-unstyled pb-3">
          <NavLink tabIndex="0" className="otherLink" tag={Link} to={'/'} onClick={ () => props.toggle() }>
            Home
          </NavLink>

          <SubMenu title="CHAPTERS" className="title" number="1" changeCollapse={changeCollapse} collapseset={!collapseset} toggle={props.toggle} items={submenus[0]}/>
          <SubMenu title="IMPACT STORIES" className="title" number="2" changeCollapse={changeCollapse} collapseset={collapseset} toggle={props.toggle} items={submenus[1]}/>

          <NavLink className="otherLink" tag={Link} to={'/make-a-change'} onClick={ () => props.toggle() }>
              Make A Change
          </NavLink>
          <NavLink className="otherLink" tag={Link} to={'/credits'} onClick={ () => props.toggle() }>
              Credits
          </NavLink>
          <NavLink className="otherLink" tag={Link} to={'/get-involved'}  onClick={ () => props.toggle() }>
              Get Involved
          </NavLink>

          <NavLink className="otherLink" tag={Link} to={'/about'} onClick={ () => props.toggle() }>
            About
          </NavLink>
          <NavLink className="otherLink" tag={Link} to={'/for-teachers'} onClick={ () => props.toggle() }>
              For Teachers
          </NavLink>
            <a href="mailto:colinscheyen@hotmail.com?subject=Contact%20from%20Mum's%20the%20Word" target="_blank" rel="noopener noreferrer">Contact Us</a>
        </Nav>
      </div>
    </div>
  )
  };

  const submenus = [
    [
      {
        title: "The Secret",
        target: "/the-secret"
      },
      {
        title: "Hidden Away",
        target: "/hidden-away",
      },
      {
        title: "Separation",
        target: "/separation",
      },
      {
        title: "The Silence",
        target: "/the-silence"
      },
      {
        title: "Healing",
        target: "/healing",
      },
      {
        title: "Epilogue",
        target: "/epilogue",
      }
    ],
    [
      {
        title: "The Secret",
        target: "/the-secret-stories"
      },
      {
        title: "Hidden Away",
        target: "/hidden-away-stories",
      },
      {
        title: "Separation",
        target: "/separation-stories",
      },
      {
        title: "The Silence",
        target: "/the-silence-stories"
      },
      {
        title: "Healing",
        target: "/healing-stories",
      }
    ]
  ]


export default SideBar;
