import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'


import {
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';



const SocialMediaButtons = (props) => (
    <React.Fragment>
    <FacebookShareButton className="footerButton"
      url={props.url}
      quote={props.text}>
        <FontAwesomeIcon icon={faFacebookF}/>
    </FacebookShareButton>
    <div className="greySlash">|</div>
    <TwitterShareButton className="footerButton"
      url={props.url}
      title={props.text}>
      <FontAwesomeIcon icon={faTwitter} />
    </TwitterShareButton>
  </React.Fragment>
)

export default SocialMediaButtons
