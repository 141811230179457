
const jsonPrivate = [
  { id: 1, title: "How did social workers convince you to sign the adoption paper?",
    img: "/contributors/colleen_r.jpg",
    alt: "Colleen",
    hover: "/contributors/colleen_r_hover.jpg",
    holding: "/contributors/colleen1_r_screen.jpg",
    preview: "https://vimeo.com/368393193",
    url: "https://vimeo.com/368391148",
    description: 'Colleen describes the moment when her social worker convinced her to sign the adoption papers.'},
  { id: 2, title: "Why did you sign the adoption papers?",
    img: "/contributors/sharon.jpg",
    hover: "/contributors/sharon_hover.jpg",
    holding: "/contributors/sharon_screen.jpg",
    preview: "https://vimeo.com/368394144",
    url: "https://vimeo.com/368392378",
    description: "Sharon explains how the social workers pressured her into signing the adoption papers."},
  { id: 3, title: "How hospitals, social workers, and churches work together to separate mothers and children?",
    img: "/contributors/stella.jpg",
    hover: "/contributors/stella_hover.jpg",
    holding: "/contributors/stella_screen.jpg",
    preview: "https://vimeo.com/368394271",
    url: "https://vimeo.com/368392612",
    description: 'How did hospitals, social workers, and churches work together to separate mothers and children?'},
  { id: 4, title: "How did the social workers convince you to give up your child?",
    img: "/contributors/pearl.jpg",
    alt: "Pearl",
    hover: "/contributors/pearl_hover.jpg",
    holding: "/contributors/pearl_screen.jpg",
    preview: "https://vimeo.com/368393743",
    url: "https://vimeo.com/368391814",
    description: "Pearl describes the methods used by social workers to convince her to sign the adoption papers."},
  { id: 5, title: "How were unwed mothers treated at the hospital?",
    img: "/contributors/june.jpg",
    alt: "June",
    hover: "/contributors/june_hover.jpg",
    holding: "/contributors/june_screen.jpg",
    preview: "https://vimeo.com/368393432",
    url: "https://vimeo.com/368391512",
    description: "June describes how unwed mothers were treated by hospital staff."},
  { id: 6, title: "How were unwed mothers treated at the hospital?",
    img: "/contributors/karen.jpg",
    alt: "Karen",
    hover: "/contributors/karen_hover.jpg",
    holding: "/contributors/karen_screen.jpg",
    preview: "https://vimeo.com/368393296",
    url: "https://vimeo.com/368391358",
    description: "Karen describes how unwed mothers were treated by hospital staff."},
  { id: 7, title: "Why was shame and guilt neccessary in the adoption system?",
    img: "/contributors/michele.jpg",
    hover: "/contributors/michele_hover.jpg",
    holding: "/contributors/michele_screen.jpg",
    preview: "https://vimeo.com/368393846",
    url: "https://vimeo.com/368391996",
    description: "Michele describes the cruelty of the adoption system and how shame and guilt was used to manipulate young mothers."},
  { id: 8, title: "How did you spend your limited time with your child?",
    img: "/contributors/celine.jpg",
    alt: "Celine",
    hover: "/contributors/celine_hover.jpg",
    holding: "/contributors/celine_screen.jpg",
    preview: "https://vimeo.com/368394958",
    url: "https://vimeo.com/368393101",
    description: "Celine describes the few fleeting moments that she shared with her child before losing her to adoption."},
  { id: 9, title: "How did some mothers escape the adoption mandate?",
    img: "/contributors/judy.jpg",
    hover: "/contributors/judy_hover.jpg",
    holding: "/contributors/judy_screen.jpg",
    preview: "https://vimeo.com/368394008",
    url: "https://vimeo.com/368392128",
    description: "Judy describes her role in supporting a young mother to escape the adoption mandate while in the hospital."},
  { id: 10, title: "How did society justify the removal of newborn children from their mothers?",
    img: "/contributors/mary.jpg",
    hover: "/contributors/mary_hover.jpg",
    holding: "/contributors/mary_screen.jpg",
    preview:"https://vimeo.com/368394422",
    url: "https://vimeo.com/368392821",
    description: 'Mary describes the extreme methods that churches and social working agencies used to separate mother and child across Canada.'},
  { id: 11, title: "Why did society at that time think that closed adoption was the best solution?",
    img: "/contributors/wendy.jpg",
    alt: "Wendy",
    hover: "/contributors/wendy_hover.jpg",
    holding: "/contributors/wendy1_screen.jpg",
    preview:"https://vimeo.com/368391674",
    url: "https://vimeo.com/368391674",
    description: 'Wendy describes how the adoption system used secrecy for short-term gain but created long-term problems.'},

]

// --------------------------------------

const jsonPublic = [
  { id: 1, title: "How did social workers convince you to sign the adoption paper?",
    img: "/contributors/colleen_r.jpg",
    alt: "Colleen",
    hover: "/contributors/colleen_r_hover.jpg",
    holding: "/contributors/colleen1_r_screen.jpg",
    preview: "https://vimeo.com/368393193",
    url: "https://vimeo.com/368391148",
    description: 'Colleen describes the moment when her social worker convinced her to sign the adoption papers.'},
  { id: 2, title: "Why did you sign the adoption papers?",
    img: "/contributors/sharon.jpg",
    hover: "/contributors/sharon_hover.jpg",
    holding: "/contributors/sharon_screen.jpg",
    preview: "https://vimeo.com/368394144",
    url: "https://vimeo.com/368392378",
    description: "Sharon explains how the social workers pressured her into signing the adoption papers."},
  { id: 3, title: "How hospitals, social workers, and churches work together to separate mothers and children?",
    img: "/contributors/stella.jpg",
    hover: "/contributors/stella_hover.jpg",
    holding: "/contributors/stella_screen.jpg",
    preview: "https://vimeo.com/368394271",
    url: "https://vimeo.com/368392612",
    description: 'How did hospitals, social workers, and churches work together to separate mothers and children?'},
  { id: 4, title: "How did the social workers convince you to give up your child?",
    img: "/contributors/pearl.jpg",
    alt: "Pearl",
    hover: "/contributors/pearl_hover.jpg",
    holding: "/contributors/pearl_screen.jpg",
    preview: "https://vimeo.com/368393743",
    url: "https://vimeo.com/368391814",
    description: "Pearl describes the methods used by social workers to convince her to sign the adoption papers."},
  { id: 5, title: "How were unwed mothers treated at the hospital?",
    img: "/contributors/june.jpg",
    alt: "June",
    hover: "/contributors/june_hover.jpg",
    holding: "/contributors/june_screen.jpg",
    preview: "https://vimeo.com/368393432",
    url: "https://vimeo.com/368391512",
    description: "June describes how unwed mothers were treated by hospital staff."},
  { id: 6, title: "How were unwed mothers treated at the hospital?",
    img: "/contributors/karen.jpg",
    alt: "Karen",
    hover: "/contributors/karen_hover.jpg",
    holding: "/contributors/karen_screen.jpg",
    preview: "https://vimeo.com/368393296",
    url: "https://vimeo.com/368391358",
    description: "Karen describes how unwed mothers were treated by hospital staff."},
  { id: 7, title: "Why was shame and guilt neccessary in the adoption system?",
    img: "/contributors/michele.jpg",
    hover: "/contributors/michele_hover.jpg",
    holding: "/contributors/michele_screen.jpg",
    preview: "https://vimeo.com/368393846",
    url: "https://vimeo.com/368391996",
    description: "Michele describes the cruelty of the adoption system and how shame and guilt was used to manipulate young mothers."},
  { id: 8, title: "How did you spend your limited time with your child?",
    img: "/contributors/celine.jpg",
    alt: "Celine",
    hover: "/contributors/celine_hover.jpg",
    holding: "/contributors/celine_screen.jpg",
    preview: "https://vimeo.com/368394958",
    url: "https://vimeo.com/368393101",
    description: "Celine describes the few fleeting moments that she shared with her child before losing her to adoption."},
]

function getJson() {
  const mode = process.env.REACT_APP_MODE

  if (mode === "private") {
    return jsonPrivate
  } else {
    return jsonPublic
  }
}

export default getJson
