import React from 'react';
import { Container, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import SocialMenu from './SocialMenu.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare, faTimes} from '@fortawesome/free-solid-svg-icons'

class ModalExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    let data = this.props.data
    return (
        <div>
          <div className="share" onClick={this.toggle}>
            <div className="share-slideout">share</div>

            <button aria-label={`share ${data.title}`}>
              <FontAwesomeIcon icon={faShare} />
            </button>

          </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>


         <ModalBody>
           <Container>
             <Row>
                <FontAwesomeIcon onClick={ () => this.toggle() } icon={faTimes} className='closeshare'/><br/><br/>
                <p>{`Share ${data.alt} story "${data.title}" with your friends`}</p><br/><br/>
                <SocialMenu url={data.url} text={`Share ${data.alt} story "${data.title}" www.mumsthewordthefilm.com`}/>
             </Row>
           </Container>
         </ModalBody>
        </Modal>
      </div>
    );
  }
};

export default ModalExample;
