import React, { Fragment } from "react";
import { Container, Button, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import SocialMenu from './SocialMenu.js'
import styled from 'styled-components';
import VideoComponent from '../components/VideoComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const ModalContainer = styled(Modal)`
  max-width: 60rem !important;
  padding: 0;
  .modal-content {
    padding: 0;
  }
  .modal-dialog {
    padding: 2%;
  }
  .modal-body {
    padding: 0;
  }
  button {
    z-index: 9999;
    position: absolute;
    color: white;
    right: -0.75em;
    top: 0;
  }
`

ModalContainer.defaultProps = {
  "data-id": "ModalContainer"
}

class ModalExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggleOpen = this.toggleOpen.bind(this);
    this.toggleClose = this.toggleClose.bind(this)
  }

  toggleOpen() {
    this.props.vidToBeMuted(false);
    this.props.vidisPlaying(true)

      this.setState({modal: true});
  }

  toggleClose() {
      this.props.vidisPlaying(false)
    this.props.vidToBeMuted(true);
    this.setState({modal: false});

  }

  render() {
    let data = this.props.data
    return (
    <Fragment>
          <div className="videoModal" onClick={this.toggleOpen} aria-label="Watch Our Tutorial">
              Watch Our Tutorial
          </div>

        <ModalContainer isOpen={this.state.modal} onClick={this.toggleClose} className={this.props.className}>
         <Button color="white" onClick={this.toggle} className="close-tutorial"><FontAwesomeIcon icon={faTimes} /></Button>

         <ModalBody>
             <Row>
             <VideoComponent
               ariaLabel="Tutorial"
               videoUrl="https://vimeo.com/383139447"
               play={true}
               volume={0.6}
               imageUrl={"/black.jpg"}
               controls={true}
               onVideoEndedCallback=""
               muted={false} />
             </Row>
         </ModalBody>
        </ModalContainer>
      </Fragment>
    );
  }
};

export default ModalExample;
