import React, { useState, useEffect, Fragment } from "react";

import VideoComponent from '../components/VideoComponent'
import { Container } from 'reactstrap';


const TheSecret = props => {
  const [muted, setMuted] = useState(false)

  const [videoUrl] = useState('https://vimeo.com/361867754')

  const loadStories = () => {
    props.history.push('/the-secret-stories')
  }

  useEffect(() => {
    setMuted(props.muted) // 2. props changed? do this
  }, [props]) // 1. if props has changed?, no.

  useEffect(() => {
  props.newPageLoad()
  }, [])
  return (
    <Fragment>
      <Container fluid  className="confineWidth no-padding">
      <VideoComponent
        ariaLabel="Chapter One Video"
        videoUrl={videoUrl}
        play={true}
        controls={true}
        imageUrl={"/secret.jpg"}
        onVideoEndedCallback={loadStories}
        onVideoPlaying={props.vidisPlaying}
        muted={muted} />
        </Container>
    </Fragment>
  )
}

export default TheSecret;
