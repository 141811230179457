import React, { useState, useEffect, Fragment } from "react";

import VideoComponent from '../components/VideoComponent'
import { Container } from 'reactstrap';

const Healing = props => {
  const [muted, setMuted] = useState(false)
  const [videoUrl] = useState('https://vimeo.com/361862564')

  const loadStories = () => {
    props.history.push('/healing-stories')
  }

  useEffect(() => {
    setMuted(props.muted) // 2. props changed? do this
  }, [props]) // 1. if props has changed?, no.

  useEffect(() => {
  props.newPageLoad()
  }, [])

  return (
    <Fragment>
      <Container fluid  className="confineWidth no-padding">
      <VideoComponent
        ariaLabel="Chapter Six Video"
        videoUrl={videoUrl}
        play={true}
        imageUrl={"/healing.jpg"}
        controls={true}
        onVideoEndedCallback={loadStories}
        muted={muted} />
      </Container>
    </Fragment>
  )
}

export default Healing;
