import React, { Fragment} from "react";
import { Container, Row, Col } from 'reactstrap';

const MakeChange = props => {
  return (
    <Fragment>
      <Container className="changeBlock">
        <div>
          <Row>
            <Col sm="12">
            <h2>MAKE A CHANGE</h2><br/>
            </Col>

            <Col xs="12"  md="12" lg="5">
            If you have been touched by this interactive experience, we ask that you please sign the following petition and encourage the government of Canada to take action and acknowledge the harm that governments, churches, and social working agencies created.
              <br/><br/>
              You can also share our project on social media and help teach other Canadians about this tragedy.  Thank you for listening and giving your support.
              <br/><br/>
            </Col>

            <Col md="12" lg="7">

              <a href="http://chng.it/xTFFx2znM9" className="shameClick" target="_blank" rel="noopener noreferrer">
                <img src="/shame_banner.jpg" aria-label="change it campaign link" alt="change it campaign link"/>
                <img src="/shame_hover.jpg" alt="change it campaign link hover" aria-label="change it campaign link hover" className="shame-hover"/>
              </a>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  )
}

export default MakeChange;
