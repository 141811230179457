import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import VideoComponent from './VideoComponent'
import VideoComponentBackground from './VideoComponentBackground'
import Gallery from './GalleryNew';
import AllLocations from './AllLocations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faChevronRight, faChevronLeft, faSortUp, faShare } from '@fortawesome/free-solid-svg-icons'

const ShowStories = props => {
  // const [showContent, setShowContent] = useState(true);
  const [data] = useState(props.data);
  const [nextNumber] = useState(props.nextNumber);
  const [showMap, toggleMap] = useState(false);
  const [nextName] = useState(props.nextName);
  const [nextRoute] = useState(props.nextRoute);
  const [prevName] = useState(props.prevName);
  const [prevNumber] = useState(props.prevNumber);
  const [prevRoute] = useState(props.prevRoute);
  const [currentData, setCurrentData] = useState(props.highlightData[0])
  const [url, setUrl] = useState("")
  const [reset, setReset] = useState(false)
  const [ready, setReady] = useState(true)
  const [muted, setMuted] = useState(false)
  const { isLandscape, isIE, isEdge } = props
  const [mainReady, setMainReady] = useState(false)
  const [showIntro, beenShown] = useState(false)

  const [background, setBackground] = useState(true)

  const currentLocation = window.location.pathname;


  useEffect(() => {
    setMuted(props.muted) // 2. props changed? do this
  }, [props]) // 1. if props has changed?, no.

  useEffect(() => {
  props.newPageLoad()
  toggleMap(false)
  }, [])

  const toggleMapview = () => {
    toggleMap(!showMap)
  }

  const passClick = (item) => {
    beenShown(true)
    setCurrentData(item)
    setUrl("")
    setReady(false)
    setMainReady(false)
  };

  const setVideoReady = (action) => {
    setReady(true)
  };

  const vidReady = () => {
    props.vidisPlaying()
    setMainReady(true)
  };

  const handlePlay = (e, item) => {
    setUrl(item.url)
    setBackground(false)
  };

  const handleClose = () => {
      setUrl("")
      setReset(true)
      props.newPageLoad(false)
  }


  let className = isLandscape ? 'galleryFixed gallerySection animated fadeInUp delay-0.5s' : 'gallerySection animated fadeInUp delay-0.5s';

  if (isIE || isEdge) {
    className = 'galleryFixed gallerySection animated fadeInUp delay-0.5s'
  }

    return (
      <React.Fragment>
      {(currentLocation === "/hidden-away-stories" && showMap) &&
        <AllLocations toggleMapview={toggleMapview}/>
      }

      { !showMap &&

      <Container fluid  className="popupOverlay confineWidth no-padding">

        <Row className="no-margin">
          <Col className="no-padding">
             <React.Fragment>
             { !url && (
               <div className="animated fadeInDown delay-0.5s">

               <div id="VideoComponent">
                 <VideoComponentBackground
                   ariaLabel={`preview video of ${currentData.title}`}
                   videoUrl={currentData.preview} setVideoReady={setVideoReady}
                   play={true} imageUrl={currentData.holding}/>
               </div>
                 <div className="overlay">
                   <div className="textArea">
                     <h2>{currentData.title}</h2>
                    <span className='xs-hidden'>{currentData.description}</span>
                     { currentData.url && (

                         <button type="button" className="playButton" onClick={
                             (e) => handlePlay(e, currentData)
                           }><em><FontAwesomeIcon icon={faPlay} /> Play</em>
                         </button>


                     )}
                   </div>

                   { nextRoute &&

                     <Button className="button nextPart" aria-label="Next Part Button">
                     <Link to={nextRoute} aria-label={`Advance to Chapter ${nextNumber} ${nextName}`}>
                        <span>Advance To</span><span>Chapter {nextNumber}</span>
                        <em>{nextName}</em><FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                    </Button>


                  }
                  { prevRoute &&
                    <Button className="button prevPart xs-hidden" aria-label="Previous Part Button">
                    <Link to={prevRoute} aria-label={`Return to Chapter ${prevNumber} ${prevName}`}>
                       <span>Return to</span><span>Chapter {prevNumber}</span>
                       <em>{prevName}</em><FontAwesomeIcon icon={faChevronLeft} />
                   </Link>
                   </Button>
                  }

                </div>

              </div>

             )}
             { url && (
               <div>
                 <VideoComponent
                   ariaLabel={`${currentData.title} video`}
                   videoUrl={currentData.url}
                   play={true}
                   controls={true}
                   imageUrl={currentData.holding}
                   onVideoPlaying={vidReady}
                   onVideoEndedCallback={handleClose}
                   muted={muted} />
                   <button type="button" className="close videoclose" aria-label="Close" onClick={
                     (e) => handleClose()
                    }>
                     <span aria-hidden={'true'}>&times;</span>
                   </button>

                   </div>

                 )}
                 {(currentLocation === "/the-secret-stories") && !showIntro &&
                 <div className="impactClick"><FontAwesomeIcon icon={faSortUp} /> Click here to move to the next chapter</div>
                 }

             </React.Fragment>
          </Col>
        </Row>

          { !url && (


            <div className={className}>
            {(currentLocation === "/the-secret-stories") && !showIntro &&
            <div className="impactIntro">Following each chapter, you will have the opportunity to explore interactive spaces like this one. Please click on the images below to ask questions and learn from those who were directly impacted by the adoption mandate. You can also share these stories on social media by clicking on the <FontAwesomeIcon icon={faShare} /> icon</div>
            }


        <Gallery currentLocation={currentLocation} reset={reset} toggleMapview={toggleMapview} data={data} currentData={currentData} passClick={passClick}/>

        </div>
      )}

      </Container>
    }
      </React.Fragment >
   )
 }


Container.propTypes = {
  fluid:  PropTypes.bool
}

export default ShowStories;
