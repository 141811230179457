
const jsonPrivate = [
  { id: 1, title: "Did you find your child?",
    img: "/contributors/loretta.jpg",
    hover: "/contributors/loretta_hover.jpg",
    holding: "/contributors/loretta_screen.jpg",
    preview:"https://vimeo.com/368887984",
    url: "https://vimeo.com/368887659",
    description: 'Loretta read a poem about the silence she kept for decades and the impact that her reunion with one of her children has made on her life.'},
  { id: 2, title: "Did you find your mother?",
    img: "/contributors/daniel.jpg",
    hover: "/contributors/daniel_hover.jpg",
    holding: "/contributors/daniel_screen.jpg",
    preview: "https://vimeo.com/368887873",
    url: "https://vimeo.com/368886314",
    description: "Dan speaks about his search for his mother and the difficulties he faced."},
  { id: 3, title: "Did you find your child?",
    img: "/contributors/sharon.jpg",
    hover: "/contributors/sharon_hover.jpg",
    holding: "/contributors/sharon_screen.jpg",
    preview: "https://vimeo.com/368888231",
    url: "https://vimeo.com/368884016",
    description: "Sharon explain the incredible coincidences that brought her to her daughter."},
  { id: 4, title: "Why is it important for adoptees to know where they came from?",
    img: "/contributors/colleen_c.jpg",
    hover: "/contributors/colleen_c_hover.jpg",
    holding: "/contributors/colleen_c_screen.jpg",
    preview: "https://vimeo.com/368888172",
    url: "https://vimeo.com/368883819",
    description: "Colleen C describes why so many adoptees find it important to connect with their kin and learn about where they came from."},
  { id: 5, title: "How do closed records affect mothers, fathers, and adoptees?",
    img: "/contributors/theresa.jpg",
    hover: "/contributors/theresa_hover.jpg",
    holding: "/contributors/theresa_screen.jpg",
    preview: "https://vimeo.com/368888594",
    url: "https://vimeo.com/368886052",
    description: "Theresa speaks about the painful history of closed records in Prince Edward Island"},
  { id: 6, title: "What is a disclosure veto and how did it impact you?",
    img: "/contributors/karen.jpg",
    hover: "/contributors/karen_hover.jpg",
    holding: "/contributors/karen_screen.jpg",
    preview: "https://vimeo.com/368888328",
    url: "https://vimeo.com/368884206",
    description: "Karen describes her search for her son and the difficulties she faced with disclosure vetos."},
  { id: 7, title: "What issues do adoptees face when they want to know about their family's medical history?",
    img: "/contributors/mary.jpg",
    hover: "/contributors/mary_hover.jpg",
    holding: "/contributors/mary_screen.jpg",
    preview: "https://vimeo.com/368888529",
    url: "https://vimeo.com/368885590",
    description: "Mary describes the challenges she faced when wanting to know about her family's medical history."},
  { id: 8, title: "What are the challenges that adoptees face when searching for their families?",
    img: "/contributors/abby.jpg",
    hover: "/contributors/abby_hover.jpg",
    holding: "/contributors/abby_screen.jpg",
    preview: "https://vimeo.com/368888405",
    url: "https://vimeo.com/368884645",
    description: "Abby describes the common issues and emotions that adoptees face when searching for their kin."},
  { id: 9, title: "How did you learn to deal with the emotions when searching for your lost ones?",
    img: "/contributors/wendy.jpg",
    hover: "/contributors/wendy_hover.jpg",
    holding: "/contributors/wendy1_screen.jpg",
    preview: "https://vimeo.com/368888089",
    url: "https://vimeo.com/368883592",
    description: "Wendy describes her reunion story and the reason for starting Adoption Support Kinship (ASK)"
  },
  { id: 10, title: "What role do churches have in supporting mothers, fathers, and adoptees who have been impacted by adoption loss?",
    img: "/contributors/rev.jpg",
    hover: "/contributors/rev_hover.jpg",
    holding: "/contributors/rev_screen.jpg",
    preview: "https://vimeo.com/368888468",
    url: "https://vimeo.com/368885134",
    description: "Rev. Hayward describes the new role that churches can play in helping families to heal and move on." },
]

// --------------------------------------

const jsonPublic = [
  { id: 1, title: "Did you find your child?",
    img: "/contributors/loretta.jpg",
    hover: "/contributors/loretta_hover.jpg",
    holding: "/contributors/loretta_screen.jpg",
    preview:"https://vimeo.com/368887984",
    url: "https://vimeo.com/368887659",
    description: 'Loretta read a poem about the silence she kept for decades and the impact that her reunion with one of her children has made on her life.'},
  { id: 2, title: "Did you find your mother?",
    img: "/contributors/daniel.jpg",
    hover: "/contributors/daniel_hover.jpg",
    holding: "/contributors/daniel_screen.jpg",
    preview: "https://vimeo.com/368887873",
    url: "https://vimeo.com/368886314",
    description: "Dan speaks about his search for his mother and the difficulties he faced."},
  { id: 3, title: "Did you find your child?",
    img: "/contributors/sharon.jpg",
    hover: "/contributors/sharon_hover.jpg",
    holding: "/contributors/sharon_screen.jpg",
    preview: "https://vimeo.com/368888231",
    url: "https://vimeo.com/368884016",
    description: "Sharon explain the incredible coincidences that brought her to her daughter."},
  { id: 4, title: "Why is it important for adoptees to know where they came from?",
    img: "/contributors/colleen_c.jpg",
    hover: "/contributors/colleen_c_hover.jpg",
    holding: "/contributors/colleen_c_screen.jpg",
    preview: "https://vimeo.com/368888172",
    url: "https://vimeo.com/368883819",
    description: "Colleen C describes why so many adoptees find it important to connect with their kin and learn about where they came from."},
  { id: 5, title: "How do closed records affect mothers, fathers, and adoptees?",
    img: "/contributors/theresa.jpg",
    hover: "/contributors/theresa_hover.jpg",
    holding: "/contributors/theresa_screen.jpg",
    preview: "https://vimeo.com/368888594",
    url: "https://vimeo.com/368886052",
    description: "Theresa speaks about the painful history of closed records in Prince Edward Island"},
  { id: 6, title: "What is a disclosure veto and how did it impact you?",
    img: "/contributors/karen.jpg",
    hover: "/contributors/karen_hover.jpg",
    holding: "/contributors/karen_screen.jpg",
    preview: "https://vimeo.com/368888328",
    url: "https://vimeo.com/368884206",
    description: "Karen describes her search for her son and the difficulties she faced with disclosure vetos."},
  { id: 7, title: "What issues do adoptees face when they want to know about their family's medical history?",
    img: "/contributors/mary.jpg",
    hover: "/contributors/mary_hover.jpg",
    holding: "/contributors/mary_screen.jpg",
    preview: "https://vimeo.com/368888529",
    url: "https://vimeo.com/368885590",
    description: "Mary describes the challenges she faced when wanting to know about her family's medical history."},
    { id: 8, title: "What are the challenges that adoptees face when searching for their families?",
    img: "/contributors/abby.jpg",
    hover: "/contributors/abby_hover.jpg",
    holding: "/contributors/abby_screen.jpg",
    preview: "https://vimeo.com/368888405",
    url: "https://vimeo.com/368884645",
    description: "Abby describes the common issues and emotions that adoptees face when searching for their kin."},
]

function getJson() {
  const mode = process.env.REACT_APP_MODE

  if (mode === "private") {
    return jsonPrivate
  } else {
    return jsonPublic
  }
}

export default getJson
