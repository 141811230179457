
import React, { useState, useEffect } from "react";
import Swiper from "react-id-swiper";
import Modal from './Modal.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'


const ManipulatingSwiper = (props) => {
// Swiper instance
const [swiper, updateSwiper] = useState(null);

const [currentselected, updateSelected] = useState(props.currentData.id);
    const [selected, update] = useState(0);
// Params definition
const [data] = useState(props.data);
const params = {
  lazy: true,
  slidesPerView: 3,
  spaceBetween: 10,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  renderPrevButton: () => <button className="swiper-button-prev"><FontAwesomeIcon icon={faChevronLeft} /></button>,
  renderNextButton: () => <button className="swiper-button-next"><FontAwesomeIcon icon={faChevronRight} /></button>,
   mousewheel: true,
   keyboard: {
     enabled: true,
 },
   breakpoints: {
     768: {
       slidesPerView: 3,
       spaceBetween: 5
     },
     640: {
       slidesPerView: 2,
       spaceBetween: 5
     },
     320: {
       slidesPerView: 1,
       spaceBetween: 10
     }
   },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
     dynamicBullets: true
  },
  getSwiper: updateSwiper // Get swiper instance callback
};

// Manipulate swiper from outside

const handleClick = (e, item) => {
  props.passClick(item)
  updateSelected(item.id)
  update(item.id - 1)
}

useEffect(() => {
    update(currentselected - 1)
}, [props.reset]);

// Add eventlisteners for swiper after initializing
useEffect(
  () => {
    if (swiper !== null) {
      if (props.reset) {
        if (props.currentLocation === "/hidden-away-stories") {
          var i = 0;
          while (i < selected + 1 ) {
            swiper.slideNext()
            i++;
          }
        } else {
          var i = 0;
          while (i < selected ) {
            swiper.slideNext()
            i++;
          }
        }
      }
    }
  },
  [swiper]
);

return (
  <div>
    <Swiper getSwiper={updateSwiper} {...params}>

      { data.map((item, index) => {
        if (item.title === "map") {
          return (
            <div key={`slide_map`}>
            <button aria-label={`button to open map`} className="item"  key="mapid" onClick={props.toggleMapview}
            >
            <img src="/contributors/map.jpg" className="image"/>
            <img src="/contributors/map_hover.jpg" className="hoverOverlay"/>
            </button>
            </div>
          )
        }
        return (
        <div key={`slide_${index}`}>
          <Modal data={item} />
          <button
            aria-label={`button to open ${item.title} popup`}
            className={`swiper-slide item ${selected === index ? 'selected' : ""}`}
            key={item.id}
            onClick={(e, props) => handleClick(e, item)}>
           <img src={item.img} className="swiper-lazy image" alt={`Interview subject ${item.alt}`} aria-label={`Photo of interview subject ${item.alt}`}/>
           <img src={item.hover} alt={`Interview subject ${item.alt} hover`} className="swiper-lazy hoverOverlay"/>
          </button>
        </div>
        )
      })
    }
  </Swiper>
  </div>
);
};

export default ManipulatingSwiper;
