import React from 'react'
import { Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'


import {
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';



const SocialMenu = (props) => (
    <React.Fragment>
      <Col sm={6} className="no-padding-left">
        <FacebookShareButton className="footerButton"
          url={props.url}
          quote={props.text}>
            <FontAwesomeIcon icon={faFacebookF}/><span>&nbsp;&nbsp;</span> Facebook
        </FacebookShareButton>
      </Col>
      <Col sm={6} className="no-padding-right">
        <TwitterShareButton className="footerButton"
          url={props.url}
          title={props.text}>
          <FontAwesomeIcon icon={faTwitter} /><span>&nbsp;&nbsp;</span> Twitter
         </TwitterShareButton>
        </Col>
  </React.Fragment>
)

export default SocialMenu
