
const jsonPrivate = [
  { id: 0, title: "map"},
  { id: 1, title: "What was it like living inside of a maternity home?",
    img: "/contributors/theresa.jpg",
    alt: "Theresa",
    hover: "/contributors/theresa_hover.jpg",
    holding: "/contributors/theresa1_screen.jpg",
    preview: "https://vimeo.com/368376527",
    url: "https://vimeo.com/368372070",
    description: "Standing in front of the same maternity home that she stayed in, Theresa describes the culture of secrecy and shame inside of the maternity home."},
  { id: 2, title: "What was it like living in a maternity home?",
    img: "/contributors/karen.jpg",
    alt: "Karen",
    hover: "/contributors/karen_hover.jpg",
    holding: "/contributors/karen_screen.jpg",
    preview: "https://vimeo.com/368377758",
    url: "https://vimeo.com/368374475",
    description: "Karen describes her experiences staying inside a maternity home in Toronto, Ontario."},
  { id: 3, title: "How were you convinced that you would be an “inadequate mother”?",
    img: "/contributors/celine.jpg",
    alt: "Celine",
    hover: "/contributors/celine_hover.jpg",
    holding: "/contributors/celine_screen.jpg",
    preview: "https://vimeo.com/368377839",
    url: "https://vimeo.com/368374907",
    description: "Celine describes the culture of maternity homes and the coercive methods that were used."},
  { id: 4, title: "How did social workers convince you to sign the adoption paper?",
    img: "/contributors/colleen_r.jpg",
    alt: "Colleen",
    hover: "/contributors/colleen_r_hover.jpg",
    holding: "/contributors/colleen_r_screen.jpg",
    preview: "https://vimeo.com/368376756",
    url: "https://vimeo.com/368372854",
    description: 'Colleen describes the moment when her social worker convinced her to sign the adoption papers.'},
  { id: 5, title: "What was it like living in a maternity home?",
    img: "/contributors/pearl.jpg",
    alt: "Pearl",
    hover: "/contributors/pearl_hover.jpg",
    holding: "/contributors/pearl_screen.jpg",
    preview: "https://vimeo.com/368377514",
    url: "https://vimeo.com/368373200",
    description: "Pearl describes her experiences staying inside a maternity home in Saskatoon, Saskatchewan."},
  { id: 6, title: "What is/was a maternity home?",
    img: "/contributors/michele.jpg",
    alt: "Michele",
    hover: "/contributors/michele_hover.jpg",
    holding: "/contributors/michele_screen.jpg",
    preview: "",
    url: "https://vimeo.com/368375321",
    description: "Michele describes her observations of the culture of maternity homes as a journalist for the Globe and Mail in the 1960s."},
  { id: 7, title: "What was it like living inside of a maternity home?",
    img: "/contributors/suzanne.jpg",
    alt: "Suzanne",
    hover: "/contributors/suzanne_hover.jpg",
    holding: "/contributors/suzanne_screen.jpg",
    preview: "https://vimeo.com/368375747",
    url: "https://vimeo.com/368369871",
    description: "Standing in front of the same maternity home that she stayed in, Suzanne describes the culture of secrecy and shame inside of the maternity home."},
  { id: 8, title: "Why was there such a need for secrecy in post-war adoption?",
    img: "/contributors/wendy.jpg",
    alt: "Wendy",
    hover: "/contributors/wendy_hover.jpg",
    holding: "/contributors/wendy_screen.jpg",
    preview:"https://vimeo.com/368376265",
    url: "https://vimeo.com/368371093",
    description: 'Wendy explains the culture of secrecy that was central to the post-war adoption mandate.'},
  { id: 9, title: "How were you convinced that you would be an inadequate mother?",
    img: "/contributors/sharon.jpg",
    alt: "Sharon",
    hover: "/contributors/sharon_hover.jpg",
    holding: "/contributors/sharon_screen.jpg",
    preview: "https://vimeo.com/368375594",
    url: "https://vimeo.com/368369518",
    description: "Sharon explains how social workers and doctors manipulated her into giving her child up for adoption."},
  { id: 10, title: "What was the church's role in running maternity homes?",
    img: "/contributors/rev.jpg",
    alt: "Reverend Daniel Hayward",
    hover: "/contributors/rev_hover.jpg",
    holding: "/contributors/rev_screen.jpg",
    preview: "https://vimeo.com/368376002",
    url: "https://vimeo.com/368371318",
    description: "Reverend Daniel Hayward explains the United Church's role in both funding and operating maternity homes across Canada."},
]

// --------------------------------------

const jsonPublic = [
  { id: 0, title: "map"},
  { id: 1, title: "What was it like living inside of a maternity home?",
    img: "/contributors/theresa.jpg",
    alt: "Theresa",
    hover: "/contributors/theresa_hover.jpg",
    holding: "/contributors/theresa1_screen.jpg",
    preview: "https://vimeo.com/368376527",
    url: "https://vimeo.com/368372070",
    description: "Standing in front of the same maternity home that she stayed in, Theresa describes the culture of secrecy and shame inside of the maternity home."},
  { id: 2, title: "What was it like living in a maternity home?",
    img: "/contributors/karen.jpg",
    alt: "Karen",
    hover: "/contributors/karen_hover.jpg",
    holding: "/contributors/karen_screen.jpg",
    preview: "https://vimeo.com/368377758",
    url: "https://vimeo.com/368374475",
    description: "Karen describes her experiences staying inside a maternity home in Toronto, Ontario."},
  { id: 3, title: "How were you convinced that you would be an “inadequate mother”?",
    img: "/contributors/celine.jpg",
    alt: "Celine",
    hover: "/contributors/celine_hover.jpg",
    holding: "/contributors/celine_screen.jpg",
    preview: "https://vimeo.com/368377839",
    url: "https://vimeo.com/368374907",
    description: "Celine describes the culture of maternity homes and the coercive methods that were used."},
  { id: 4, title: "How did social workers convince you to sign the adoption paper?",
    img: "/contributors/colleen_r.jpg",
    alt: "Colleen",
    hover: "/contributors/colleen_r_hover.jpg",
    holding: "/contributors/colleen_r_screen.jpg",
    preview: "https://vimeo.com/368376756",
    url: "https://vimeo.com/368372854",
    description: 'Colleen describes the moment when her social worker convinced her to sign the adoption papers.'},
  { id: 5, title: "What was it like living in a maternity home?",
    img: "/contributors/pearl.jpg",
    alt: "Pearl",
    hover: "/contributors/pearl_hover.jpg",
    holding: "/contributors/pearl_screen.jpg",
    preview: "https://vimeo.com/368377514",
    url: "https://vimeo.com/368373200",
    description: "Pearl describes her experiences staying inside a maternity home in Saskatoon, Saskatchewan."},
  { id: 6, title: "What is/was a maternity home?",
    img: "/contributors/michele.jpg",
    alt: "Michele",
    hover: "/contributors/michele_hover.jpg",
    holding: "/contributors/michele_screen.jpg",
    preview: "",
    url: "https://vimeo.com/368375321",
    description: "Michele describes her observations of the culture of maternity homes as a journalist for the Globe and Mail in the 1960s."},
  { id: 7, title: "What was it like living inside of a maternity home?",
    img: "/contributors/suzanne.jpg",
    alt: "Suzanne",
    hover: "/contributors/suzanne_hover.jpg",
    holding: "/contributors/suzanne_screen.jpg",
    preview: "https://vimeo.com/368375747",
    url: "https://vimeo.com/368369871",
    description: "Standing in front of the same maternity home that she stayed in, Suzanne describes the culture of secrecy and shame inside of the maternity home."},
]

function getJson() {
  const mode = process.env.REACT_APP_MODE

  if (mode === "private") {
    return jsonPrivate
  } else {
    return jsonPublic
  }
}

export default getJson
