import React, { Fragment } from "react";
import { Container, Row, Col } from 'reactstrap';

const About = props => {

  return (
    <Fragment>
    <Container>
      <Row>
        <Col sm="12">
          <div className="aboutBlock">
            <h2 className="logo">Mum's The Word</h2><br/>
            Mum's the word is a old expression that means to say nothing; don't reveal a secret.
            <br/><br/>
            Our project, <b>Mum's The Word</b>, is an interactive documentary on the history and legacy of the adoption mandate in Canada as seen through the eyes of those who lived it and those who still feel it today. In the post-war period, approximately 350,000 women throughout Canada were coerced into giving their "illegitimate children” up for adoption by churches, social workers, government agencies, and sometimes their own families. The impact and stigma that this mandate created is only beginning to be understood today.
            <br/><br/>
            We intend to share the stories and lived experiences of mothers and adoptees from across Canada who were denied their basic human rights. By telling these stories we hope to share their knowledge and lived experiences and give space to those who have been denied it for far too long.
         </div>
      </Col>
   </Row>
</Container>
    </Fragment>
  )
}

export default About;
