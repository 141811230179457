import React, { useState, useEffect } from 'react';
import  { NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const SubMenu = props => {

  const [collapsed, setCollapsed] = useState(false)

  const { title, items } = props;
    const toggleNavbar = () =>  {
      setCollapsed(!collapsed)
      props.changeCollapse()
    }
  useEffect(() => {
    setCollapsed(props.collapseset) // 2. props changed? do this
  }, [props.collapseset]) // 1. if props has changed?, no.


    return (
      <div id="accordionGroup" className="Accordion">

          <button onClick={toggleNavbar} className={classNames({'menu-open': !collapsed})}
                  aria-expanded="true"
                  aria-controls={title}
                  id={`accordion${props.number}id`}>
            <span className="title">
               {title}
              <span className="Accordion-icon"></span>
            </span>
          </button>



        { !collapsed &&
          <div>
          <div id={title}
             role="region"
             aria-labelledby="accordion1id"

             className={classNames('items-menu',{'mb-1': !collapsed})} />
     <fieldset  className="Accordion-panel">
     {items.map((item, index) => (
       <NavItem key={index} >
         <NavLink tag={Link} className="title" tabIndex={item.tabIndex || ""} className="otherLink" to={item.target} onClick={
                       () => props.toggle()
                     }>
           {item.title}
         </NavLink>
       </NavItem>
       ))}

     </fieldset></div>
        }





    </div>


  )
}

export default SubMenu;
