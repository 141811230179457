import React, { Fragment } from "react";
import { Container, Row, Col } from 'reactstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
  duration: 1200,
})


const About = props => {

  return (
    <Fragment>
    <Container>
      <Row>
        <Col sm="12">
          <div className="credits">
          <br/>  <br/>
          <br/>  <br/>
          <br/>  <br/><br/>  <br/>


          <div data-aos="fade-in">
          <h3 className="logo">MUM'S THE WORD</h3>
          <b>© EVENINGS AND WEEKENDS PRODUCTIONS 2019</b><br/>
          </div>
          <br/>  <br/><br/>
          <div className="textCredits">
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>DIRECTED BY</span></Col>
            <Col sm="6" className="alignLeft"><em>COLIN SCHEYEN</em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>EXECUTIVE PRODUCER</span></Col>
            <Col sm="6" className="alignLeft"><em>COLIN SCHEYEN</em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>DIRECTOR OF PHOTOGRAPHY</span></Col>
            <Col sm="6" className="alignLeft"><em>JOSEPHINE ANDERSON</em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>VIDEOGRAPHERS</span></Col>
            <Col sm="6" className="alignLeft"><em data-aos="fade-in">  ALIFIYAH HUSSAIN<br/></em>
              <em data-aos="fade-in">PASCAL HUYNH</em><br/>
              <em data-aos="fade-in">ELVIRA PARENT</em><br/>
              <em data-aos="fade-in">IAN RAYBURN</em><br/>
              <em data-aos="fade-in">MASIH KHALATBARI</em><br/>
              <em data-aos="fade-in">SHAUN RYAN SAVARD</em><br/>
              <em data-aos="fade-in">COLIN SCHEYEN<br/></em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>EDITORS</span></Col>
            <Col sm="6" className="alignLeft"><em data-aos="fade-in">MASIH KHALATBARI<br/></em>
              <em data-aos="fade-in">ELVIRA PARENT</em><br/>
              <em data-aos="fade-in">COLIN SCHEYEN<br/></em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>LOCATION SOUND MIXER</span></Col>
            <Col sm="6" className="alignLeft"><em>GEORGE PETROVIC</em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>SOUND DESIGN</span></Col>
            <Col sm="6" className="alignLeft"><em>STEPHANIE HAMELIN TOMALA</em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>FOLEY ARTIST</span></Col>
            <Col sm="6" className="alignLeft"><em>SAM MONGEAU</em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-left">
            <Col sm="6" className="alignRight"><span>FEATURING</span></Col>
            <Col sm="6" className="alignLeft"><em data-aos="fade-in">BERNADETTE RYMER</em><br/>
            <br/>
            <em data-aos="fade-in">&<br/></em>
            <br/>
            <em data-aos="fade-in">VALERIE ANDREWS<br/></em>
            <em data-aos="fade-in">THERESA AYLWARD<br/></em>
            <em data-aos="fade-in">SUZANNE BURNS<br/></em>
            <em data-aos="fade-in">JUNE BYERS<br/></em>
            <em data-aos="fade-in">MONICA BYRNE<br/></em>
            <em data-aos="fade-in">JUDY CHEVERIE<br/></em>
            <em data-aos="fade-in">COLLEEN CLARK<br/></em>
            <em data-aos="fade-in">LORETTA ROSE DEATSCHER<br/></em>
            <em data-aos="fade-in">SENATOR ART EGGLETON<br/></em>
            <em data-aos="fade-in">REV. DANIEL HAYWARD<br/></em>
            <em data-aos="fade-in">DENISE HASKETT<br/></em>
            <em data-aos="fade-in">ABBY FORERO HILTY<br/></em>
            <em data-aos="fade-in">MICHELE LANDSBERG<br/></em>
            <em data-aos="fade-in">DANIEL LAFRENIERE<br/></em>
            <em data-aos="fade-in">MARY MACDONALD<br/></em>
            <em data-aos="fade-in">KAREN MARTEN<br/></em>
            <em data-aos="fade-in">NANCY MITTON-RILEY<br/></em>
            <em data-aos="fade-in">PEARL NEWFELD<br/></em>
            <em data-aos="fade-in">SHARON PEDERSEN<br/></em>
            <em data-aos="fade-in">SENATOR CHANTEL PETITCLERC<br/></em>
            <em data-aos="fade-in">LORI PRINGLE<br/></em>
            <em data-aos="fade-in">WENDY ROWNEY<br/></em>
            <em data-aos="fade-in">COLLEEN RUD<br/></em>
            <em data-aos="fade-in">STELLA SHEPPARD<br/></em>
            <em data-aos="fade-in">SARAH THOMPSON<br/></em>
            <em data-aos="fade-in">CELINE WILLIAMSON<br/></em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>RESEARCHERS</span></Col>
            <Col sm="6" className="alignLeft"><em data-aos="fade-in">KENDRA BELLE<br/></em>
            <em data-aos="fade-in">JULIA GETCHELL<br/></em>
            <em data-aos="fade-in">MARIYAM KHAJA<br/></em>
            <em data-aos="fade-in">MASIH KHALATBARI<br/></em>
            <em data-aos="fade-in">COLIN SCHEYEN<br/></em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>COLOURIST</span></Col>
            <Col sm="6" className="alignLeft"><em>COLIN SCHEYEN</em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>INTERACTIVE DESIGN</span></Col>
            <Col sm="6" className="alignLeft"><em>MICHELLE CLARK</em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>INTERACTIVE DESIGN CONSULTANT</span></Col>
            <Col sm="6" className="alignLeft"><em>KEVIN GOJMERAC</em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>ADDITIONAL DESIGN</span></Col>
            <Col sm="6" className="alignLeft"><em>MARIANA DE QUEIROZ<br/>
              ALIFIYAH HUSSAIN<br/></em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>ANIMATOR</span></Col>
            <Col sm="6" className="alignLeft"><em>GAVIN BALL</em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>STORY WRITERS</span></Col>
            <Col sm="6" className="alignLeft"><em data-aos="fade-in">EMMA BRITO<br/></em>
              <em data-aos="fade-in">MARIANA DE QUEIROZ<br/></em>
              <em data-aos="fade-in">ALIFIYAH HUSSAIN<br/></em>
              <em data-aos="fade-in">MASIH KHALATBARI<br/></em>
              <em data-aos="fade-in">ELVIRA PARENT<br/></em>
              <em data-aos="fade-in">COLIN SCHEYEN<br/></em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>INTERVIEWERS</span></Col>
            <Col sm="6" className="alignLeft">  <em data-aos="fade-in">MARIYAM KHAJA<br/></em>
              <em data-aos="fade-in">ELVIRA PARENT<br/></em>
              <em data-aos="fade-in">COLIN SCHEYEN<br/></em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>SOCIAL MEDIA MANAGERS</span></Col>
            <Col sm="6" className="alignLeft"><em data-aos="fade-in">ALIFYAH HUSSAIN<br/></em>
                <em data-aos="fade-in">MARIYAM KHAJA<br/></em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-in">
            <Col sm="6" className="alignRight"><span>TRANSLATION AND SUBTITLING</span></Col>
            <Col sm="6" className="alignLeft"><em data-aos="fade-in">CLAIRE BOURNEUF<br/></em>
                <em data-aos="fade-in">MASIH KHALATBARI<br/></em>
                <em data-aos="fade-in">ELVIRA PARENT<br/></em></Col>
          </Row>
          <br/>
          <Row data-aos="fade-right">
            <Col sm="6" className="alignRight"><span>SPECIAL THANK YOU</span></Col>
            <Col sm="6" className="alignLeft"><em data-aos="fade-in">ABBIE ADELMAN<br/></em>
            <em data-aos="fade-in">ADOPTION SUPPORT KINSHIP<br/></em>
            <em data-aos="fade-in">FARIHA AHMED<br/></em>
            <em data-aos="fade-in">JUDGE HERBERT ALLARD<br/></em>
            <em data-aos="fade-in">TAMARA ALMEIDA<br/></em>
            <em data-aos="fade-in">VALERIE ANDREWS<br/></em>
            <em data-aos="fade-in">THERESA AYLWARD<br/></em>
            <em data-aos="fade-in">JEFFREY BARRETT<br/></em>
            <em data-aos="fade-in">MAUREEN BAYLESS<br/></em>
            <em data-aos="fade-in">KENDRA BELLE<br/></em>
            <em data-aos="fade-in">SADIE BRYAN<br/></em>
            <em data-aos="fade-in">SHILOAH BELL-HIGGINS<br/></em>
            <em data-aos="fade-in">JACQUELINE BENNETT<br/></em>
            <em data-aos="fade-in">KAYDEEN BENNETT<br/></em>
            <em data-aos="fade-in">JOANNA BRYNIARSKA<br/></em>
            <em data-aos="fade-in">MONICA BYRNE<br/></em>
            <em data-aos="fade-in">JEREMY CARADONNA<br/></em>
            <em data-aos="fade-in">JUSTIN CARROLL<br/></em>
            <em data-aos="fade-in">WALTER CACALIERI<br/></em>
            <em data-aos="fade-in">ARDEN CHURCH<br/></em>
            <em data-aos="fade-in">SHEENA CLOWATER<br/></em>
            <em data-aos="fade-in">SANDRA COMEAU<br/></em>
            <em data-aos="fade-in">VINCENT COTE<br/></em>
            <em data-aos="fade-in">AMY CRAWFORD<br/></em>
            <em data-aos="fade-in">LORETTA ROSE DEUTSCHER<br/></em>
            <em data-aos="fade-in">DOCUMENTARY ORGANIZATION OF CANADA<br/></em>
            <em data-aos="fade-in">DOC INSTITUTE<br/></em>
            <em data-aos="fade-in">JOSEPH DONOVAN<br/></em>
            <em data-aos="fade-in">JEREMY FRIEDMAN<br/></em>
            <em data-aos="fade-in">BARBARA FULLER<br/></em>
            <em data-aos="fade-in">CARA GIBBONS<br/></em>
            <em data-aos="fade-in">KEVIN GOJMERAC<br/></em>
            <em data-aos="fade-in">STEFAN GRAMBART<br/></em>
            <em data-aos="fade-in">ERIN GREENO<br/></em>
            <em data-aos="fade-in">WILLIAM HAMMERSLEY<br/></em>
            <em data-aos="fade-in">PATTY HAILS<br/></em>
            <em data-aos="fade-in">TYLER HALL<br/></em>
            <em data-aos="fade-in">CAT HENDERSON<br/></em>
            <em data-aos="fade-in">MICHELLE HOWATT<br/></em>
            <em data-aos="fade-in">MELISSA HUDSON<br/></em>
            <em data-aos="fade-in">SANDRA JARVIE<br/></em>
            <em data-aos="fade-in">LESLEY JOHNSON<br/></em>
            <em data-aos="fade-in">JANE KENNY<br/></em>
            <em data-aos="fade-in">CHRISTINA KONECNY<br/></em>
            <em data-aos="fade-in">DANIEL LAFRENIERE<br/></em>
            <em data-aos="fade-in">JOHN LAING<br/></em>
            <em data-aos="fade-in">STEPHEN LEWIS<br/></em>
            <em data-aos="fade-in">ROCH LONGUEEPEE<br/></em>
            <em data-aos="fade-in">DARLENE LUND<br/></em>
            <em data-aos="fade-in">WALEED NASSAR<br/></em>
            <em data-aos="fade-in">VELVET MARTIN<br/></em>
            <em data-aos="fade-in">QUENTIN MATHESON<br/></em>
            <em data-aos="fade-in">KATE MCKENZIE<br/></em>
            <em data-aos="fade-in">JANET MCKENZIE-COHEN<br/></em>
            <em data-aos="fade-in">JANET MCILVEEN<br/></em>
            <em data-aos="fade-in">RON MANN<br/></em>
            <em data-aos="fade-in">KADRAH MENSAH<br/></em>
            <em data-aos="fade-in">JAMIE MILLER<br/></em>
            <em data-aos="fade-in">NANCY MITTON-RILEY<br/></em>
            <em data-aos="fade-in">KEN MOFFAT<br/></em>
            <em data-aos="fade-in">ANDREW MOIR<br/></em>
            <em data-aos="fade-in">JULIA MORGAN<br/></em>
            <em data-aos="fade-in">CHARLOTTE O'CONNOR MACAULAY<br/></em>
            <em data-aos="fade-in">MAUREEN NEWELL<br/></em>
            <em data-aos="fade-in">OYIN OLALEKAN<br/></em>
            <em data-aos="fade-in">QAIS PASHA<br/></em>
            <em data-aos="fade-in">SHARON PEDERSEN<br/></em>
            <em data-aos="fade-in">BILL AND JOYCE PENNY<br/></em>
            <em data-aos="fade-in">PETAR PETROVIC<br/></em>
            <em data-aos="fade-in">AMANDA PETRYSCHUK<br/></em>
            <em data-aos="fade-in">DR. SUSAN PRESTON<br/></em>
            <em data-aos="fade-in">DAVID PICKLE<br/></em>
            <em data-aos="fade-in">EUGENIA POWELL<br/></em>
            <em data-aos="fade-in">ALBERT PRENDERGAST<br/></em>
            <em data-aos="fade-in">LORI PRINGLE<br/></em>
            <em data-aos="fade-in">IAN RAYBURN<br/></em>
            <em data-aos="fade-in">MICHAEL REDMOND<br/></em>
            <em data-aos="fade-in">EVELYN ROBINSON<br/></em>
            <em data-aos="fade-in">VICTOR RODRIGUEZ<br/></em>
            <em data-aos="fade-in">LEOR ROTCHILD<br/></em>
            <em data-aos="fade-in">CARLA ROWAN<br/></em>
            <em data-aos="fade-in">WENDY ROWNEY<br/></em>
            <em data-aos="fade-in">COLLEEN RUD<br/></em>
            <em data-aos="fade-in">NANDO RUEDA<br/></em>
            <em data-aos="fade-in">RYERSON UNIVERSITY<br/></em>
            <em data-aos="fade-in">BERNADETTE RYMER<br/></em>
            <em data-aos="fade-in">SONIA SAKAMOTO-JOG<br/></em>
            <em data-aos="fade-in">ADAM SHAMOON<br/></em>
            <em data-aos="fade-in">SARAH SHELSON<br/></em>
            <em data-aos="fade-in">DAVID SCHEYEN<br/></em>
            <em data-aos="fade-in">JASON SCHEYEN<br/></em>
            <em data-aos="fade-in">JOSEPHINE SCHEYEN<br/></em>
            <em data-aos="fade-in">KRISTA SCHEYEN<br/></em>
            <em data-aos="fade-in">NAOMI SCHEYEN<br/></em>
            <em data-aos="fade-in">MARG SCHEYEN<br/></em>
            <em data-aos="fade-in">NICK SCHEYEN<br/></em>
            <em data-aos="fade-in">STELLA SHEPPARD<br/></em>
            <em data-aos="fade-in">WAYNE SHIPLEY<br/></em>
            <em data-aos="fade-in">BEN SILVERMAN<br/></em>
            <em data-aos="fade-in">MARY SISCO<br/></em>
            <em data-aos="fade-in">AVERY STROK<br/></em>
            <em data-aos="fade-in">HALI AND MYRL STUS<br/></em>
            <em data-aos="fade-in">VERA SZOKE<br/></em>
            <em data-aos="fade-in">DR. VERONICA STRONG-BOAG<br/></em>
            <em data-aos="fade-in">POLIA TEIF<br/></em>
            <em data-aos="fade-in">NADIA TAVAZZANI<br/></em>
            <em data-aos="fade-in">SCOTT TOWNEND<br/></em>
            <em data-aos="fade-in">THE TRANSMEDIA ZONE (RYERSON UNIVERSITY)<br/></em>
            <em data-aos="fade-in">URBAN POST<br/></em>
            <em data-aos="fade-in">AJ VAAGE<br/></em>
            <em data-aos="fade-in">YAMILKA VASQUEZ<br/></em>
            <em data-aos="fade-in">ANGELA WARNER<br/></em>
            <em data-aos="fade-in">DR. SAMANTHA WEHBI<br/></em>
            <em data-aos="fade-in">LULU WEI<br/></em>
            <em data-aos="fade-in">KIM WELLS<br/></em>
            <em data-aos="fade-in">LINDA WIGMORE<br/></em>
            <em data-aos="fade-in">CELINE WILLIAMSON<br/></em>
            <em data-aos="fade-in">ROB WILSON<br/></em>
            <em data-aos="fade-in">WINNIPEG ARCHITECTURE FOUNDATION<br/></em>
            <em data-aos="fade-in">BETTY XIE<br/></em>
            <em data-aos="fade-in">EMILIA ZBORALSKA<br/></em>
            <em data-aos="fade-in">PETER ZIOBROWSKI<br/></em>
            <em data-aos="fade-in">EUI YONG ZONG<br/></em></Col>
          </Row>
          <br/><br/><br/>
          <div data-aos="fade-in">THIS FILM WOULD NOT BE POSSIBLE IF NOT FOR THE TIRELESS WORK AND ADVOCACY OF ORIGINS CANADA AND VALERIE ANDREWS.<br/></div>
          <br/>
          <div data-aos="fade-in">
          THIS FILM WOULD ALSO NOT BE POSSIBLE IF IT WERE NOT FOR THE GENEROUS SUPPORT OF THE TORONTO ARTS COUNCIL AND CANADA COUNCIL FOR THE ARTS<br/></div>
          <br/>
          <div data-aos="fade-in">EVERYONE ELSE WHO HELPED TO MAKE THIS PROJECT HAPPEN<br/></div>
          <br/>
          <br/>
          <br/>
          <b data-aos="fade-in">ARCHIVAL FOOTAGE/PHOTOGRAPHS</b><br/>
          <br/>
          <div data-aos="fade-in">
            <em>BCCA 4 – 224:  United Church Home for Girls:  interior, class room, ca. 1955,</em> Courtesy of Pacific Mountain Region Archives of The United Church of Canada<br/>
          </div>
          <br/>
          <div data-aos="fade-in"><em>BCCA 4 – 226:  United Church Home for Girls:  interior, dining room, ca. 1955,</em> Courtesy of Pacific Mountain Region Archives of The United Church of Canada<br/>
          <br/></div>
          <div data-aos="fade-in"><em>BCCA 4 – 227:  United Church Home for Girls:  interior, kitchen, ca. 1955,</em> Courtesy of Pacific Mountain Region Archives of The United Church of Canada<br/></div>
          <br/>
          <div data-aos="fade-in">
          <em>BCCA 4 – 228:  United Church Home for Girls:  interior, dormitory, ca. 1955,</em> Courtesy of Pacific Mountain Region Archives of The United Church of Canada<br/>
          <br/></div>
          <div data-aos="fade-in"><em>BCCA 4 – 229:  United Church Home for Girls:  interior, recreation room, ca. 1955,</em> Courtesy of Pacific Mountain Region Archives of The United Church of Canada<br/></div>
          <br/>
          <div data-aos="fade-in">
          <em>BCCA 4 – 230:  United Church Home for Girls:  interior, visitors’ room, ca. 1955,</em> Courtesy of Pacific Mountain Region Archives of The United Church of Canada<br/>
          <br/></div>
          <div data-aos="fade-in"><em>United Church Phone For Girls (Exterior)</em> Courtesy of Pacific Mountain Region Archives of The United Church of Canada<br/></div>
          <br/>
          <div data-aos="fade-in"><em>Female nurse standing in a nursery in the Ottawa Civic Hospital Credit:</em> National Film Board of Canada. Photothèque / Library and Archives Canada. 1971-271 NPC, item 4309944<br/></div>
          <br/>
          <div data-aos="fade-in">
          <em>Victor Home, Pam BV 2613 Y5A2,</em> Courtesy of the United Church of Canada Archives<br/>
          <br/>
          </div>
          <div data-aos="fade-in">
          <em>Brochure 3r,</em> Courtesy of Pacific Mountain Region Archives of The United Church of Canada<br/>
          <br/>
          </div>
          <div data-aos="fade-in">
          <em>Victor Home Statement on Admissions and Adoptions, Pam BV 2613 V5A2 C.2,</em> Courtesy of the<br/>United Church of Canada Archives<br/>
          <br/>
          </div>
          <div data-aos="fade-in">
          <em>A pregnant teenage girl goes to a home for unwed mothers during the 1970s (75306934)</em> provided by Pond5
          <br/><br/>
          </div>
          <div data-aos="fade-in">
          <em>A pregnant teenage girl goes to a home for unwed mothers during the 1970s (101905523)</em>
          provided by Pond5<br/>
          <br/></div>
          <div data-aos="fade-in">
          <em>Close Girl's Hand Feeling Tall (04289772)</em> Courtesy of the<br/>provided by Pond5
          <br/>
          <br/>
          </div>
          <div data-aos="fade-in">
          <em>Crocuses Grow spring time laps (032377228)</em>provided by Pond5
          <br/>
          <br/>
          </div>
          <div data-aos="fade-in">
          National Apology for Forced Adoptions, Parliament of Australia, Thursday, March 21 2013<br/>
          <br/></div>
          <div data-aos="fade-in">
          Image of Parliament Building of Canada, Courtesy of BRAWSTOCK.COM<br/>
          </div>
          <br/>
          <div data-aos="fade-in">
          Unethical Adoption Practices During the Post-War Era, CPAC, Social Affairs, Science and Technology, March 20, 2018<br/>
          </div>
          <br/>
          <div data-aos="fade-in">
          The Shame is Ours, The Senate of Canada report cover, July 2018<br/>
          </div>
          <div data-aos="fade-in">
          <em>“Are We Failing The Unwed Mother”</em>courtesy of the United Church Archives<br/>
          </div>
          <div data-aos="fade-in">
          <em>“Fatherless Children: Victims of Patchwork Laws”</em>by Michele Landsberg courtesy of The Globe and Mail. 27 Feb 1964: 13<br/>
          </div>
          <br/>
          <br/>
          <br/>
          <Row>
            <Col sm="6" className="alignRight"><span  data-aos="fade-in">IMAGES OF MATERNITY HOMES</span></Col>
            <Col sm="6" className="alignLeft"><em data-aos="fade-in">Anchorage Maternity Home- St. John's, NFLD<br/><br/></em>
              <em data-aos="fade-in">Armagh Maternity Home- Mississauga, ON<br/><br/></em>
            <em data-aos="fade-in">  Bethany Maternity Home-  Halifax, NS<br/><br/></em>
              <em data-aos="fade-in">Bethany Home- Ottawa, ON image courtesy of the City of Ottawa<br/><br/></em>
              <em data-aos="fade-in">Bethany Home- Toronto image courtesy of BlogTO. 20.09.2017<br/><br/></em>
              <em data-aos="fade-in">Bethany Home- Winnipeg, MB<br/><br/></em>
              <em data-aos="fade-in">Bethany Home- London, ON image courtesy of Origins Canada<br/><br/></em>
              <em data-aos="fade-in">Creche D-Youville- Montreal, QC<br/><br/></em>
              <em data-aos="fade-in">Creche St. Vincent de Paul- Montreal, QC<br/><br/></em>
              <em data-aos="fade-in">Elizabeth House- Montreal, QC<br/><br/></em>
              <em data-aos="fade-in">Evangeline Home- St. John's, NFLD image courtesy of Provincial Archives of New Brunswick (Photo # P210-3092)<br/><br/></em>
              <em data-aos="fade-in">Faith Haven- Windsor, ON image courtesy of Origins Canada<br/><br/></em>
              <em data-aos="fade-in">Florence Booth House, Thunder Bay, ON image courtesy of Origins Canada<br/><br/></em>
              <em data-aos="fade-in">Foly Joly- Trois Rivieres, QC<br/><br/></em>
              <em data-aos="fade-in">Friendly House- Montreal, QC<br/><br/></em>
              <em data-aos="fade-in">Grace Haven- Hamilton, ON<br/><br/></em>
              <em data-aos="fade-in">Grace Haven- Montreal, QC<br/><br/></em>
              <em data-aos="fade-in">Grace Haven- Saskatoon, SK image courtesy of Origins Canada<br/><br/></em>
              <em data-aos="fade-in">Grace Haven- Steinbach, MB<br/><br/></em>
              <em data-aos="fade-in">Grace Haven- Sydney, NS<br/><br/></em>
              <em data-aos="fade-in">House of the Guardian Angel- Halifax, NS image courtesy of Donahoe's Magazine Thomas B. Noonan & Company 1882<br/><br/></em>
              <em data-aos="fade-in">Humewood House- Toronto, ON<br/><br/></em>
              <em data-aos="fade-in">Maywood Home- Vancouver, BC<br/><br/></em>
              <em data-aos="fade-in">Providence Creche- Calgary, AB image courtesy of mycalgary.com<br/><br/></em>
              <em data-aos="fade-in">Rosalie Hall- Toronto, ON image courtesy of Rosalie Hall<br/><br/></em>
              <em data-aos="fade-in">St. Mary's Home- Ottawa, ON<br/><br/></em>
              <em data-aos="fade-in">St. Martin's Manor- Hamilton, ON<br/><br/></em>
              <em data-aos="fade-in">St. Monica's House- Waterloo, ON<br/><br/></em>
              <em data-aos="fade-in">Sundale Manor- Chatham, ON image courtesy of City of Chatham-Kent Municipal Museums<br/><br/></em>
              <em data-aos="fade-in">United Church Home For Girls- Burnaby, BC image courtesy of Pacific Mountain Region Archives of The United Church of Canada<br/><br/></em>
              <em data-aos="fade-in">Villa Marie-Claire- Sherbrooke, QC<br/><br/></em>
              <em data-aos="fade-in">Villa Rosa- Winnipeg, MB image courtesy of Winnipeg Architecture Foundation<br/><br/></em>
              <em data-aos="fade-in">Villa St. Michael- Montreal, QC<br/><br/></em>
              <em data-aos="fade-in">Victor Home, Toronto, ON image courtesy of United Church of Canada Archives<br/></em></Col>
          </Row>
          <br/>
          <br/>
          <Row>
            <Col sm="12" data-aos="fade-in"><span>MUSIC</span></Col>
            <br/>
            <br/>
            <Col sm="12" data-aos="fade-in"><span>Music from Artlist.io</span></Col><br/>
            <br/>
            <br/>
            <Col sm="6" data-aos="fade-right"><span >“See You In Antioch” <br/>by Roland Bingaman</span></Col>
            <Col sm="6" data-aos="fade-left"><span>"Dusk" <br/>by Ian Post</span></Col>
            </Row>
            <br/><br/>
            <Row>
              <Col sm="6" data-aos="fade-right"><span >"Solitude" <br/>by Michele Nobler</span></Col>
              <Col sm="6" data-aos="fade-left"><span >“Reach”<br/>by Caleb Etheridge<br/></span></Col>
            </Row>
            <br/><br/>
            <Row>
              <Col sm="6" data-aos="fade-right"><span>“Rae” <br/>by Kevin Graham</span></Col>
              <Col sm="6" data-aos="fade-left"><span >"Desolate"<br/>by Zac Nelson<br/></span></Col>
          </Row>
          <br/><br/>
          <Row>
            <Col sm="6" data-aos="fade-right"><span>"Cap Clanc Nez" <br/>by Michelle Nobler</span></Col>
            <Col sm="6" data-aos="fade-left"><span>“Cora”<br/>by Kevin Graham<br/></span></Col>
          </Row>
          <br/><br/>
          <Row>
            <Col sm="6" data-aos="fade-right"><span>“Staring” <br/>by Muted</span></Col>
            <Col sm="6" data-aos="fade-left"><span>“Behind Every Decision”<br/>by Yehezkel Raz<br/></span></Col>
          </Row>
          <br/><br/>
          <Row>
            <Col sm="6" data-aos="fade-right"><span>"Under a Blanket" <br/>by Muted</span></Col>
            <Col sm="6" data-aos="fade-left"><span>“Odin” <br/>by Yehezkel Raz</span></Col>
          </Row>
          <br/><br/>
          <Row>
            <Col sm="6" data-aos="fade-right"><span>“Carousel”<br/>by Yehezkel Raz<br/></span></Col>
            <Col sm="6" data-aos="fade-left"><span>“Prelude” <br/>by This Patch of Sky</span></Col>
          </Row>
          <br/><br/>
          <Row>
            <Col sm="6" data-aos="fade-right"><span>The Candle Goes Out<br/>by Anthony Vega<br/></span></Col>
            <Col sm="6" data-aos="fade-left"><span>“Behind That Days” <br/>by Wild Tulip</span></Col>
          </Row>
          <br/><br/>
          <Row>
            <Col sm="6" data-aos="fade-right"><span>“Trapt”<br/>by Philip Daniel Zach<br/></span></Col>
            <Col sm="6" data-aos="fade-left"><span>"When Stars Align" <br/>by Caleb Etheridge</span></Col>
          </Row>
          <br/>  <br/>
          <br/>  <br/>
          <br/>  <br/><br/>  <br/>
          <br/>  <br/>  <br/>  <br/><br/>  <br/>  <br/>  <br/><br/>  <br/>  <br/>  <br/><br/>  <br/>

          <div data-aos="fade-up">
          <h3 className="logo">MUM'S THE WORD</h3>
          <b>© EVENINGS AND WEEKENDS PRODUCTIONS 2019</b><br/>
          </div>
          <br/>  <br/><br/>  <br/><br/>  <br/><br/>  <br/><br/>  <br/>  <br/><br/><br/>  <br/><br/>  <br/>
         </div>
                 </div>
      </Col>
   </Row>
</Container>
    </Fragment>
  )
}

export default About;
