
const jsonPrivate = [
  { id: 1, title: "How did your family react to the news?",
    img: "/contributors/sharon.jpg",
    alt: "Sharon",
    hover: "/contributors/sharon_hover.jpg",
    holding: "/contributors/sharon_screen.jpg",
    preview: "https://vimeo.com/363055164",
    url: "https://vimeo.com/362805637",
    description: "Sharon explains how her family reacted and tried to cover up her pregnancy. She explains the cruel and painful lengths that her family went hide this secret"},
  { id: 2, title: "Why was unwed pregnancy such a secret?",
    img: "/contributors/theresa.jpg",
    alt: "Theresa",
    hover: "/contributors/theresa_hover.jpg",
    holding: "/contributors/theresa_screen.jpg",
    preview: "https://vimeo.com/363054906",
    url: "https://vimeo.com/362802845",
    description: "Theresa explains the culture of secrecy in Canadian society that left young women powerless."},
  { id: 3, title: "How did society in the 50s and 60s view young women’s sexuality?",
    img: "/contributors/michele.jpg",
    alt: "Michele",
    hover: "/contributors/michele_hover.jpg",
    holding: "/contributors/michele_screen.jpg",
    preview: "https://vimeo.com/368882559",
    url: "https://vimeo.com/362807846",
    description: 'Legendary Canadian journalist Michele Landsberg was the first whistleblower to investigate closed adoption in the post-war era. She describes the culture of the 1950s and 60s and the "suffocating and stifling"  pressure that young women faced at that time.'},
  { id: 4, title: "Why couldn't you speak up and keep your child?",
    img: "/contributors/pearl.jpg",
    alt: "Pearl",
    hover: "/contributors/pearl_hover.jpg",
    holding: "/contributors/pearl_screen.jpg",
    preview: "https://vimeo.com/363055052",
    url: "https://vimeo.com/362804510",
    description: "Pearl describes the silence that was placed on young women and the lack of self-advocacy that was imposed upon her. "},
  { id: 5, title: "Why was adoption seen as the answer to society’s problems?",
    img: "/contributors/wendy.jpg",
    alt: "Wendy",
    hover: "/contributors/wendy_hover.jpg",
    holding: "/contributors/wendy_screen.jpg",
    preview:"https://vimeo.com/363055263",
    url: "https://vimeo.com/362807017",
    description: 'Wendy Rowney, the President of Adoption Support Kinship, (ASK) discusses how post-war society justified adoption as the "best choice" for all parties involved.'},
  { id: 6, title: "Why couldn't you speak up and keep your child?",
    img: "/contributors/june.jpg",
    alt: "June",
    hover: "/contributors/june_hover.jpg",
    holding: "/contributors/june_screen.jpg",
    preview: "https://vimeo.com/363054979",
    url: "https://vimeo.com/362803636/d404c9bf5f",
    description: "June describes the lack of family supports that left her with very few options."},

]

// --------------------------------------

const jsonPublic = [
  { id: 1, title: "How did your family react to the news?",
    img: "/contributors/sharon.jpg",
    alt: "Sharon",
    hover: "/contributors/sharon_hover.jpg",
    holding: "/contributors/sharon_screen.jpg",
    preview: "https://vimeo.com/363055164",
    url: "https://vimeo.com/362805637",
    description: "Sharon explains how her family reacted and tried to cover up her pregnancy. She explains the cruel and painful lengths that her family went hide this secret"},
  { id: 2, title: "Why was unwed pregnancy such a secret?",
    img: "/contributors/theresa.jpg",
    alt: "Theresa",
    hover: "/contributors/theresa_hover.jpg",
    holding: "/contributors/theresa_screen.jpg",
    preview: "https://vimeo.com/363054906",
    url: "https://vimeo.com/362802845",
    description: "Theresa explains the culture of secrecy in Canadian society that left young women powerless."},
  { id: 3, title: "How did society in the 50s and 60s view young women’s sexuality?",
    img: "/contributors/michele.jpg",
    alt: "Michele",
    hover: "/contributors/michele_hover.jpg",
    holding: "/contributors/michele_screen.jpg",
    preview: "https://vimeo.com/368882559",
    url: "https://vimeo.com/362807846",
    description: 'Legendary Canadian journalist Michele Landsberg was the first whistleblower to investigate closed adoption in the post-war era. She describes the culture of the 1950s and 60s and the "suffocating and stifling"  pressure that young women faced at that time.'},
  { id: 4, title: "Why couldn't you speak up and keep your child?",
    img: "/contributors/pearl.jpg",
    alt: "Pearl",
    hover: "/contributors/pearl_hover.jpg",
    holding: "/contributors/pearl_screen.jpg",
    preview: "https://vimeo.com/363055052",
    url: "https://vimeo.com/362804510",
    description: "Pearl describes the silence that was placed on young women and the lack of self-advocacy that was imposed upon her. "},
  { id: 5, title: "Why was adoption seen as the answer to society’s problems?",
    img: "/contributors/wendy.jpg",
    alt: "Wendy",
    hover: "/contributors/wendy_hover.jpg",
    holding: "/contributors/wendy_screen.jpg",
    preview:"https://vimeo.com/363055263",
    url: "https://vimeo.com/362807017",
    description: 'Wendy Rowney, the President of Adoption Support Kinship, (ASK) discusses how post-war society justified adoption as the "best choice" for all parties involved.'},
  { id: 6, title: "Why couldn't you speak up and keep your child?",
    img: "/contributors/june.jpg",
    alt: "June",
    hover: "/contributors/june_hover.jpg",
    holding: "/contributors/june_screen.jpg",
    preview: "https://vimeo.com/363054979",
    url: "https://vimeo.com/362803636/d404c9bf5f",
    description: "June describes the lack of family supports that left her with very few options."},
]

function getJson() {
  const mode = process.env.REACT_APP_MODE

  if (mode === "private") {
    return jsonPrivate
  } else {
    return jsonPublic
  }
}

export default getJson
