import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
import styled from 'styled-components';

const StyledAudio = styled(ReactPlayer)`
  display: none;
`

const playlist = [
  {
    path: "/",
    file: "Opening.mp3"
  },
  {
    path: "/the-secret-stories",
    file: "Dusk_by_IanPost_Artlist.wav"
  },
  {
    path: "/hidden-away-stories",
    file: "Desolate_by_ZacNelson_Artlist.wav"
  },
  {
    path: "/separation-stories",
    file: "CapBlancNez_by_MicheleNobler_Artlist.wav"
  },
  {
    path: "/the-silence-stories",
    file: "Staring_by_Muted_Artlist.wav"
  },
  {
    path: "/healing-stories",
    file: "UnderaBlanketOnlyPiano_by_Muted_Artlist.wav"
  }


]

const BackgroundAudio = ({ playing, muted }) => {
  // const [playing, setPlaying] = useState(false);
    const [videoMute, setVideoMute] = useState(muted)

  const pathname = window.location.pathname || null
  const playAudio = !playing

  const track = playlist.find(i => i.path === pathname)

  useEffect(() => {
      setVideoMute(muted)
  })


  if (track) {
    return (
      <StyledAudio
        className='background-audio'
        autoPlay={true}
        url={track.file}
        loop={true}
        muted={ muted}
        volume={1}
        playing={playAudio}
        config={{
         file: {
           forceAudio: true
         }
        }}
      />
    )
  } else {
    return null
  }
}

export default BackgroundAudio
