import React, { useState, useEffect, Fragment } from "react";
import { Container } from 'reactstrap';

import VideoComponent from '../components/VideoComponent'

const Epilogue = props => {
  const [muted, setMuted] = useState(false)
  const [videoUrl] = useState('https://vimeo.com/361860920')

  const loadStories = () => {
    props.history.push('/make-a-change')
  }

  useEffect(() => {
    setMuted(props.muted) // 2. props changed? do this
  }, [props]) // 1. if props has changed?, no.

  useEffect(() => {
  props.newPageLoad()
  }, [])

  return (
    <Fragment>
      <Container fluid  className="confineWidth no-padding">
      <VideoComponent
        ariaLabel="Epilogue"
        videoUrl={videoUrl}
        play={true}
        imageUrl={"/epilogue.jpg"}
        controls={true}
        onVideoEndedCallback={loadStories}
        muted={muted} />
        </Container>

    </Fragment>
  )
}

export default Epilogue;
