import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const VideoComponentBackground = props => {
  const [ready, setReady] = useState(false)
  const [videoUrl, setVideoContent] = useState(props.videoUrl);

  useEffect(() => {
    setVideoContent(props.videoUrl) // 2. props changed? do this
    setReady(false)
  }, [props.videoUrl]) // 1. if props has changed?, no.

  const setVideoReady = () => {
    // props.setVideoReady(true)
    setReady(true)
  };

  return (
    <Container fluid className="no-padding">
     <Row className="no-margin">
       <Col md className="no-padding">
        { props.imageUrl && (
          <img src={props.imageUrl} alt="holding poster" className="poster"/>
        )}
        <div className='player-wrapper' aria-label={props.ariaLabel}>
         <ReactPlayer
           className='react-player'
           url={videoUrl}
           config={{
            vimeo: {
              playerOptions: {preload: true, background: true}
            },
            tracks: [
              {
                kind: 'subtitles',
                src: 'https://api.vimeo.com/videos/358839148',
                srcLang: 'en',
                default: true,
                password: "mumstheword"
              },
            ]
          }}
           width='100%'
           muted={props.muted}
           height='100%'
           controls={false}
           onPlay={setVideoReady}
           playing={props.play}
           loop
         />
     </div>

      </Col>
     </Row>
   </Container>
 )
}

Container.propTypes = {
    // applies .container-fluid class
  fluid:  PropTypes.bool
}
export default VideoComponentBackground


// <div className="video-wrapper">
 // <ReactPlayer
 //  url={"https://vimeo.com/358839148"}
 //  config={{
 //    vimeo: {
 //      playerVars: { password: "mumstheword" }
 //    },
 //    tracks: [
 //      {
 //        kind: 'subtitles',
 //        src: 'https://api.vimeo.com/videos/358839148',
 //        srcLang: 'en',
 //        default: true,
 //        password: "mumstheword"
 //      },
 //    ]
 //  }}
 //  controls={true}
 //  playing
 //  width={900}
 //  onEnded={() => alert('movie ended')}
 //  />
 //  </div>
