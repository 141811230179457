import React, { Fragment} from "react";
import { Container, Row, Col } from 'reactstrap';

const MakeChange = props => {
  return (
    <Fragment>
      <Container className="changeBlock">
        <div>
          <Row>
            <Col sm="12">
            <h2>FOR TEACHERS</h2><br/>
            </Col>

            <Col xs="12"  md="12" lg="5">
            We want to provide educators with the resources they need to effectively use Mum's The Word in their classrooms. We believe that the Mum's The Word platform is best suited for Grades 8-12 and can be used in a variety of different subjects such as Social Studies and History, Family Studies, Religious Studies, and Heath and Sexuality. The following document is a seven lesson instructional guide. All lesson plans are merely suggestions and we recommend that you modify these resources to fit the needs of your students.
              <br/><br/>
              The creators of Mum's The Word acknowledge that the subject matter of this film is quite difficult and we encourage all teachers to provide emotional support to students who are affected by these stories. While these stories are challenging, it is important to not shy away from exploring them with your students to ensure that this part of our history does not repeat itself.
              <br/><br/>
            </Col>

            <Col md="12" lg="7">

            <a href="/MTW-Teacher's-Guide.pdf" className="teacherImg" download>
              <img src="/teacher-guide.jpg" className="banner"/>
              <img src="/teacher-guide-hover.jpg" className="banner-hover"/>

            </a>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  )
}

export default MakeChange;
