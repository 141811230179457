import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const VideoComponent = props => {
  const [videoUrl, setVideoContent] = useState(props.videoUrl);
  const [background, setBackground] = useState(props.background);
  const [playVideo, setPlayVideo] = useState(props.play)
  const [videoMute, setVideoMute] = useState(props.muted)
  const [showContent, setShowContent] = useState(true);

  const videoEnded = () => {
    props.onVideoEndedCallback(true)
  }

  const videoPlaying = (item) => {
    props.onVideoPlaying(item)
  }

  useEffect(() => {
    setShowContent(props.showContent) // 2. props changed? do this
  }, [props.showContent]) // 1. if props has changed?, no.

  useEffect(() => {
    setVideoContent(props.videoUrl) // 2. props changed? do this
  }, [props.videoUrl]) // 1. if props has changed?, no.

  useEffect(() => {
    setBackground(props.background) // 2. props changed? do this
  }, [props.background]) // 1. if props has changed?, no.


  useEffect(() => {
    setPlayVideo(props.play)
    setVideoMute(props.muted)
    setVideoContent(props.videoUrl) // 2. props changed? do this
  }, [props]) // 1. if props has changed?, no.

  return (
    <Container fluid className="no-padding">
     <Row className="no-margin">
       <Col className="no-padding">
        <img src={props.imageUrl} alt="holding poster" className="poster"/>
        <div className="player-wrapper" aria-label={props.ariaLabel}>
         <ReactPlayer

           className='react-player'
           url={videoUrl}
           config={{
            vimeo: {
              playerOptions: {preload: true}
            },
            tracks: [
              {
                kind: 'subtitles',
                src: 'https://api.vimeo.com/videos/358839148',
                srcLang: 'en',
                default: true,
                password: "mumstheword"
              },
            ]
          }}
           width='100%'
           height='100%'
           controls={props.controls}
           volume={1}
           loop={props.loop}
           muted={props.muted}
           playing={props.play}
           onEnded={() => videoEnded()}
           onReady={() => videoPlaying()}
         />
     </div>
      </Col>
     </Row>
   </Container>
 )
}

Container.propTypes = {
    // applies .container-fluid class
  fluid:  PropTypes.bool
}
export default VideoComponent


// <div className="video-wrapper">
 // <ReactPlayer
 //  url={"https://vimeo.com/358839148"}
 //  config={{
 //    vimeo: {
 //      playerVars: { password: "mumstheword" }
 //    },
 //    tracks: [
 //      {
 //        kind: 'subtitles',
 //        src: 'https://api.vimeo.com/videos/358839148',
 //        srcLang: 'en',
 //        default: true,
 //        password: "mumstheword"
 //      },
 //    ]
 //  }}
 //  controls={true}
 //  playing
 //  width={900}
 //  onEnded={() => alert('movie ended')}
 //  />
 //  </div>
