export const markers = [
{
	"id": "pin-1",
	"title": "Armagh Home",
	"description": `<div>The Armagh house was a Presbyterian-run home in Clarksville Ontario. Karen Lynn, the head of the Canadian Council of Natural Mothers, was sent the Armagh House in 1963 when she was 19 years old. There, she said she was known only as Karen No. 1 to protect her family’s reputation, and said it was clear she would not have been allowed to stay there if she did not agree to an adoption. She says “I understand that people like to move on and that there’s nothing left but the future, but for those of us who are experiencing criticism and a complete lack of understanding from many quarters of society — including from many of our children — it’s unfinished business”.
</br></br>
Karen claims she was initially relieved to be surrounded by similarly outcast women, she believes maternity homes were “warehouses” where unmarried mothers were hidden away and essentially prepped to surrender their children for adoption.
</br></br>
Source: http://nationalpost.com/news/canada/presbyterians-to-probe-maternity-homes-in-wake-of-post-forced-adoption-revelations</div>
`,
  "street": "927 Meadow Wood Road",
	"place": "Clarkville, Ontario",
		"img": "/Final Maternity Home Photos/ARMAGH.jpg",
	"lat": 43.517568,
	"lng": -79.617990
},
{
	"id": "pin-2",
	"title": "Bethel Home",
	"description": `Bethel Home was an evangelical home for unwed mothers. Built in the 1920s, the home changed multiple locations throughout its history`,
  "street": "3762 Sheppard Avenue East",
	"place": "Agincourt, Ontario",
	"lat": 43.782914,
	"lng": -79.294025
},
{
	"id": 'pin-3',
	"title": "Bethany Home - Halifax",
	"description": "The Halifax Infants Home, now known as Bethany House was run by the Salvation Army of Canada. It was built to accommodate the Halifax Infants Home (from 1900 – 1959) and subsequently sold to the Salvation Army, who ran it as the Bethany Home for unwed mothers and their children (from 1960 – 1998). The house was purchased by Saint Mary’s University in 1998, the building has accommodated educational uses",
  "street": "980 Tower Road",
	"place": "Halifax, Nova Scotia",
  "img": "/Final Maternity Home Photos/BETHANY HOME HALIFAX.jpg",
	"lat": 44.633407,
	"lng": -63.578558
},
{
	"id":'pin-4',
	"title": "Bethany Home - Ottawa",
	"description": "Bethany Home was operated by the Salvation Army of Canada",
  "street": "1140 Wellington Street West",
	"place": "Ottawa, Ontario",
	"img": "/Final Maternity Home Photos/BETHANY HOME OTTAWA.jpg",
	"lat": 45.401516,
	"lng": -75.726815
},
{
	"id": "pin-5",
	"title": "Bethany Home - Saskatoon",
	"description": "Bethany Home was operated by the Salvation Army of Canada. The building was demolished in 2012",
  "street": "802 Queen Street",
	"place": "Saskatoon, Saskatchewan",
	"img": "/Final Maternity Home Photos/BETHANY HOME SASK.jpg",
	"lat": 52.136897,
	"lng": -106.650087
},
{
	"id": 'pin-6',
	"title": "Bethany Home - Toronto",
	"description": "Bethany Home was operated by the Salvation Army of Canada",
  "street": "450 Pape Avenue",
	"place": "Toronto, Ontario",
	"img": "/Final Maternity Home Photos/BETHANY HOME TORONTO.jpg",
	"lat": 43.671536,
	"lng": -79.342090
},

{
	"id": "pin-7",
	"title": "Bethany Home - Winnipeg",
	"description": "Bethany Home in Winnipeg was operated by the Salvation Army of Canada",
  "street": "205 Arlington Street",
	"place": "Winnipeg, Manitoba",
	  "img": "/Final Maternity Home Photos/BETHANY HOME WINNIPEG.jpg",
	"lat": 49.883649,
	"lng": -97.169338
},

{
	"id": "pin-8",
	"title": "Bethany Home / Lindenview Place",
	"description": "Bethany Home, later Lindenview Place in Winnipeg, was operated by the Salvation Army of Canada",
  "street": "250 Booth Drive",
	"place": "Winnipeg, Manitoba",
	"lat": 49.883649,
	"lng": -97.169338
},


{
	"id": 'pin-9',
	"title": "Bethesda Home - London",
	"description": "The Bethesda Home had 21 bedrooms and included classrooms, offices, and a former day care centre. The Salvation Army shit it down on May 31, 2012 because of ongoing annual operating deficits.",
  "street": "54 Riverview Avenue",
	"place": "London, Ontario",
	"img": "/Final Maternity Home Photos/BETHESDA HOME LONDON.jpg",
	"lat": 42.978565,
	"lng": -81.264366
},


{
	"id": "pin-10",
	"title": "Bethesda Home - Ottawa",
	"description": "The Bethesda Home was located just west of Ottawa",
  "street": "253 Lowe Road,",
	"place": "Ashton, Ontario",
	"img": "/Final Maternity Home Photos/BETHESDA HOME.jpg",
	"lat": 45.203314,
	"lng": -76.072920
},
{
	"id": "pin-11",
	"title": "Beulah Home",
	"description": `<div>Beulah Home was founded by Maude Elizabeth Chatham as a home for unmarried mothers and their babies. Chatham, along with Mary A. Finlay (nee White) and Clara Price (nee Schaffer), rented their first house in Edmonton, Alberta at 102 Street and 103 Avenue, and the house opened on September 9, 1909. Beulah Home was interdenominational and staffed with trained nurses and other workers who instructed the young mothers in Christianity and taught them how to care for their children. For those mothers that decided not to keep their children, Beulah Home arranged their adoptions. The home closed down in 1989.
</br></br>
Upon request for official records, people are often told the records were lost in a fire,
“I am interested in how one would be able to find records for births at the Beulah Home in Alberta...I have tried most avenues available in Edmonton and was told all records were lost in a fire.” </br></br>source:http://poundpuplegacy.org/node/45441</div>
`,
  "street": "13340 101st Street",
	"place": "Edmonton, Alberta",
	"lat": 53.593634,
	"lng": -113.493616
},
{
	"id": "pin-12",
	"title": "Carrefour Bethesda",
	"description": "Carrefour Bethesda was run by the Roman Catholic Church",
  "street": "359 Rue Laviolette",
	"place": "Gatineau, Quebec",
	"lat": 45.489778,
	"lng": -75.660911
},
{
	"id": "pin-13",
	"title": "Catholic Home of the Guardian Angel",
	"description": "The Home of the Guardian Angel was operated by the Salvation Army of Canada. It opened near an orphanage on Brunswick Street in Halifax, Nova Scotia. It was known as the Catholic Infant’s Home. Single mothers were admitted to the Home after the birth of their baby. They ran their adoption program for 125 years and had to stop due to shortage of funds",
  "street": "395 Brunswick Street",
	"place": "Halifax, Nova Scotia",
	"img": "/Final Maternity Home Photos/HOUSE OF THE GUARDIAN ANGEL HALIFAX.jpg",
	"lat": 44.652414,
	"lng": -63.580646
},
{
	"id": "pin-14",
	"title": "Cedarvale Home for Unwed Mothers",
	"description": `Cedarvale was operated by The United Church of Canada. The name Cedarvale was given to it in 1928 when it became "The Ontario Home for Girls" under the United church. It became a public park in 1967`,
  "street": "185 Main Street South",
	"place": "Georgetown, Ontario",
	"lat": 43.646075,
	"lng": -79.921123
},
{
	"id": "pin-15",
	"title": "Centre Maria",
	"description": "The Maria Center dated back to May 5, 1938, and just under 2,000 young mother-girls stayed there for the entire period. This home was expanded in 1956 to become St-Coeur-de-Marie Hospital, an institution reserved exclusively for women for several years. As for the Notre-Dame Hospital, the Gray Nuns of the Cross, as the Sisters of Charity were then known, it dates back to 1927, once the former Lion d'Or hotel was converted into a hospital center; an annex had been built in 1954",
  "street": "",
	"place": "Hawkesbury, Ontario",
	"lat": 45.610107,
	"lng": -74.597434
},
{
	"id": "pin-16",
	"title": "Centre Rosalie-Jette",
	"description": "Centre Rosalie-Jette was operated by the Catholic Church and was the largest maternity home in Quebec. It had 48 beds for mothers",
  "street": "1801 Boulevard Goin East",
	"place": "Montreal, Quebec",
	"img": "/Final Maternity Home Photos/CENTRE ROSALIE-JETTE.jpg",
	"lat": 45.568838,
	"lng": -73.660666
},
{
	"id": "pin-17",
	"title": "Church Home for Girls",
	"description": `<div>In 1911, the Winnipeg Women's Union of the Presbyterian Church amalgamated with the Women's Moral Reform Council in order to undertake the support of a Social Service Rescue Home on Simcoe Street in Winnipeg. Social Service Homes, previously called Redemptive or Rescue Homes, were initially involved with rescuing women from 'white slavery', or what we now call prostitution. Often the point of initial contact would be in providing a safe place for a woman alone to await the birth of a child. Eventually, the work expanded to include work with all women who had 'fallen' and with unwed mothers.</br></br>
  Around 1929, it began to be known as the Church Home for Girls. For most of its life span, the Church Home for Girls simply offered accommodation to unmarried women awaiting the birth of their children. These children would then almost always be placed for adoption</div>`,
  "street": "2594 Henderson Highway",
	"place": "East Saint Paul, Manitoba",
	"province": "",
	"lat": 49.972681,
	"lng": -97.061301
},
{
	"id": "pin-18",
	"title": "Crèche of Youville",
	"description": "Crèche of Youville was operated by the Grey Nuns of Montreal, the Crèche closed in 1972, and the building was demolished 20 years later in 1992",
  "street": "5935 chemin de la Côte-de-Liesse,",
	"place": "Saint-Laurent, Montreal, Quebec",
	"img": "/Final Maternity Home Photos/CRECHE D'YOUVILLE.jpg",
	"lat": 45.491681,
	"lng": -73.683690
},
{
	"id": "pin-19",
	"title": "Crèche Saint Vincent de Paul",
	"description": "Crèche of Youville was operated by the Grey Nuns of Montreal",
  "street": "1210 Chemin Ste Foy",
	"place": "Quebec City, Quebec",
	"img": "/Final Maternity Home Photos/CRECHE QUEBEC.jpg",
	"lat": 46.796849,
	"lng": -71.250652
},
{
	"id": "pin-20",
	"title": "Elizabeth House",
	"description": `Elizabeth House was Co-sponsored with Presbyterian & United Church. “The records say each woman paid $5.70 daily to stay at Elizabeth House, which accommodated 18 girls at a time and initially had a wait-list. A 1969 document referenced the “ever increasing problem of unmarried parenthood” and said a girl could return to Elizabeth House after her initial “confinement” and labour — but not if she brought her baby, too.” - National Post, 2012`,
  "street": "2131 Marlowe Avenue",
	"place": "Montreal, Quebec",
	"img": "/Final Maternity Home Photos/ELIZABETH HOUSE.jpg",
	"lat": 45.475282,
	"lng": -73.606880
},

{
	"id": 'pin-21',
	"title": "Evangeline Home / Rathbone House",
	"description": "The Evangeline Home (Also known as Rathbone House) in St. John New Brunswick was operated by the Salvation Army of Canada",
  "street": "260 Princess Street",
	"place": "Saint John, New Brunswick",
	"img": "/Final Maternity Home Photos/EVANGELINE HOME.jpg",
	"lat": 45.272824,
	"lng": -66.052528
},

{
	"id": 'pin-22',
	"title": " Faith Haven Home for Girls",
	"description": "Faith Haven was operated by the Salvation Army of Canada, it was located several doors down from Grace Hospital another property owned by the Salvation Army, Faith Haven was demolished in the 1970s",
  "street": "461 Crawford Avenue",
	"place": "Windsor, Ontario",
	"img": "/Final Maternity Home Photos/FAITH HAVEN WINSOR.jpg",
	"lat": 42.312469,
	"lng": -83.048444
},

{
	"id": "pin-23",
	"title": "Foyer Joly - (known as Villa Joly 1970-1976)",
	"description": "Foyer Joly was operated by the Roman Catholic Church the Sisters of Misericordia in Montreal, it is believed to have closed its doors in 1970",
  "street": "105 Rue Joly",
	"place": "Trois-Rivières West, Quebec",
	"img": "/Final Maternity Home Photos/FOYER JOLY.jpg",
	"lat": 46.372389,
	"lng": -72.564728
},
{
	"id": "pin-24",
	"title": "Foyer Sainte Dorothee",
	"description": `Foyer Sainte-Dorothee was run by the Roman Catholic Church of Canada- The Sisters of Misericordia. It was meant to be a sort of hideaway for single mothers, specificically from affluent backgrounds, in that they assured their reputation would not get “ruined.”`,
  "street": "690 Rue Mandel",
	"place": "Laval, Quebec",
	"lat": 45.525854,
	"lng": -73.816267
},
{
	"id": "pin-25",
	"title": "Friendly Home",
	"description": `Friendly Home was an evangelical home for unwed mothers`,
  "street": "5867 Cote Saint. Antoine Road",
	"place": "Montreal, Quebec",
	"img": "/Final Maternity Home Photos/FRIENDLY HOUSE.jpg",
	"lat": 45.469620,
	"lng": -73.619535
},
{
	"id": 'pin-26',
	"title": "Glenbrook Lodge / Glenbrook Girls Home",
	"description": `It originated in 1948 as a home for unmarried mothers and their babies and was known as the Glenbrook Girls Home. The Glenbrook Girls Home continued to provide services until 1965. https://www.cylex-canada.ca/company/salvation-army-glenbrook-lodge-16638040.html`,
  "street": "18 Wood Street",
  "place": "St. John’s, Newfoundland and Labrador",
	"lat": 47.570547,
	"lng": -52.701760
},

{
	"id":'pin-27',
	"title": "Grace Haven - Hamilton - until 1973",
  "description": "Grace Haven was operated by the Salvation Army of Canada",
  "street": "245 James Street South",
	"place": "Hamilton, Ontario",
	"lat": 43.249361,
	"lng": -79.872294
},

{
	"id": "pin-28",
	"title": "Grace Haven - Hamilton - from 1973",
  "description": "Grace Haven was operated by the Salvation Army of Canada",
  "street": "138 Herkimer Street",
	"place": "Hamilton, Ontario",
	"img": "/Final Maternity Home Photos/GRACE HAVEN HAMILTON.jpg",
	"lat": 43.250946,
	"lng": -79.879846
},
{
	"id": 'pin-29',
	"title": "Grace Haven - Montreal",
	"description": "Faith Haven was operated by the Salvation Army of Canada",
  "street": "6690 Monkland Avenue",
	"place": "Montreal, Quebec",
	"img": "/Final Maternity Home Photos/GRACE HAVEN MONTREAL.jpg",
	"lat": 45.463131,
	"lng": -73.633752
},
{
	"id": 'pin-30',
	"title": "Grace Haven - Nova Scotia",
	"description": "Grace Haven was operated by the Salvation Army of Canada",
  "street": "47 Byng Avenue",
	"place": "Sydney, Nova Scotia",
	"img": "/Final Maternity Home Photos/GRACE HAVEN SYDNEY.jpg",
	"lat": 46.133008,
	"lng": -60.192286
},
{
	"id": "pin-31",
	"title": "Grace Haven - Regina",
	"description": "Grace Haven was operated by the Salvation Army of Canada",
  "street": "2301 15th Avenue",
	"place": "Regina, Saskatchewan",
	"img": "/Final Maternity Home Photos/GRACE HAVEN SASK.jpg",
	"lat": 50.442376,
	"lng": -104.614123
},
{
	"id": "pin-32",
	"title": "Grace Haven - Regina",
	"description": "Grace Haven was operated by the Salvation Army of Canada",
  "street": "3302 Dewdney Avenue",
	"place": "Regina, Saskatchewan",
	"img": "/Final Maternity Home Photos/GRACE HAVEN SASK.jpg",
	"lat": 50.455776,
	"lng": -104.628701
},

{
	"id": "pin-33",
	"title": "Grace Haven - Regina",
	"description": "Grace Haven was operated by the Salvation Army of Canada",
  "street": "2929 26th Avenue",
	"place": "Regina, Saskatchewan",
	"lat": 50.41685,
	"lng": -104.623165
},
{
	"id": 'pin-34',
	"title": "Grace Haven - Steinbach",
	"description": "Grace Haven was operated by the Salvation Army of Canada",
  "street": "PO Box 2907",
	"place": "Steinbach, Manitoba",
	"img": "/Final Maternity Home Photos/GRACE HAVEN STEINBACH.jpg",
	"lat": 49.523568,
	"lng": -96.679527
},
{
	"id": "pin-35",
	"title": "Hill Haven / Park Wood House",
	"description": "Parkwood House was also called Hill Haven. It was operated by the Salvation Army of Canada",
  "street": "1340 8th Avenue NorthWest",
	"place": "Calgary , Alberta",
	"lat": 51.060352,
	"lng": -114.091858
},
{
	"id": "pin-36",
	"title": "Humewood House",
	"description": `Humewood House in Toronto was run by the Anglican Church of Canada. On their website they recognize their past of shaming the unwed women they housed in their homes and try to correct their mistakes by striving to a redefine these woman and empower them, ensuring they get the care and support they need to raise their children,
`,
  "street": "40 Humewood Drive",
	"place": "York, Ontario",
	"img": "/Final Maternity Home Photos/HUMEWOOD HOUSE TORONTO.jpg",
	"lat": 43.684251,
	"lng": -79.427454
},

{
	"id": "pin-37",
	"title": "Jewels for Jesus Mission",
	"description": `Jewels for Jesus was founded in 1948 and run by the Evangelicals`,
  "street": "2110 Argentia Road",
	"place": "Mississauga, Ontario",
	"lat": 43.599509,
	"lng": -79.742116
},
{
	"id": "pin-38",
	"title": "L’Hôpital de la Miséricorde",
	"description": `<div>Roughly 20 percent of the “illegitimate births” in the province during the 1930s took place at the Hospital of Misericordia in Montreal, where extensive patient records were provided. One of two ways, or as mothers-within-wedlock (real or potential), or as prostitutes. In this case, they could agree to "normal" society only by their condition and its results. Within this perspective, the hospital provided a service to the society, which sought to hide deviant behavior, and the individual. Applicants for admission, the records show, were almost entirely French-Canadian Roman Catholics.</br></br>

	Upon admission they took on new identities; the pseudonyms sometimes reflect the sense of shame the nuns sought to impose. Hospital policies reinforced this sense of isolation: visitors have been discouraged, questions of outsiders have been rebuffed, and, to a large extent, the patient has been cut off from the outside world. Most tellingly, the mother was not able to name her child after birth. Most of the children were treated for adoption: only 14.6 percent left the hospital with their mothers</div>`,
  "street": "Rue St-Hubert & Boulevard René-Lévesque E",
	"img": "/Final Maternity Home Photos/L_HOPITAL MISERICORDE(1).jpg",
	"place": "Montreal, Quebec",
	"lat": 45.514625,
	"lng": -73.555932
},
{
	"id": 'pin-39',
	"title": "Lakehead Florence Booth Home / Grace Haven",
	"description": "Lakehead Florence Booth Home / Grace Haven was operated by the Salvation Army of Canada. It was directly across the street from St. Joseph’s Hospital from the 1940’s to the 1960’s. ",
  "street": "497A Lillie Street North",
	"place": "Thunder Bay, Ontario",
	"img": "/Final Maternity Home Photos/FLORENCE BOOTH.jpg",
	"lat": 48.387562,
	"lng": -89.270441
},

{
	"id": "pin-40",
	"title": "Marion Hilliard House",
	"description": `Marion Hilliard House was operated by the Anglican Church of Canada and first opened its doors in July 1967 and closed its doors on September 30, 2002. "More than 900 young women were admitted to the house from across Canada, with the majority coming from British Columbia."`,
  "street": "831 Serle Road",
	"place": ", Kamloops, British Columbia",
	"province": "",
	"lat": 50.763742,
	"lng": -120.349631
},
{
	"id": "pin-41",
	"title": "Martha House",
	"description": "Martha’s House relocated multiple times throughout its history in Regina. It was operated by the Roman Catholic Church of Canada",
  "street": "1855 2nd Avenue North",
	"place": "Regina, Saskatchewan",
	"lat": 50.472196,
	"lng": -104.607502
},

{
	"id": "pin-42",
	"title": "Maywood Home",
	"description": "Maywood in Vancouver was operated by the Salvation Army of Canada. In 1983 one former resident described Maywood Home as 'like a jail for pregnant girls.' They were told they were making a noble decision to give their babies to married couples who couldn't have children of their own. http://www.nsnews.com/news/daughter-taken-from-north-van-teen-mom-in-1983-1.348652",
  "street": "7250 Oak Street",
	"place": "Vancouver, British Columbia",
	"lat": 49.219858,
	"lng": -123.128551
},
{
	"id": "pin-43",
	"title": "Misercordia Hospital Home",
	"description": '"In the Misericordia Homes, women were also required to stay for 6 months after the birth to pay the home back for costs of confinement and delivery. The chores done  in the homes were designed to teach women domestic skills and  an emphasis was placed on the reformative effect of the work. This was particularly prevalent in homes run by  religious organizations, which assumed that the women needed to be redeemed; the pregnancy was more than a mistake, it was a demonstration  of the state of a woman’s soul . The reaction  of women to the rigid rules was varied, some believed they were lucky to be taken in, others became very angry and rebelled, and some withdrew completely." from Nowhere Else to Go Homes for Unwed Mothers in Canada during the 20Th Century by Nancy Schnarr',
  "street": "Georgina Avenue",
	"place": "Haileybury, Ontario",
	"lat": 47.444478,
	"lng": -79.635415
},
{
	"id": "pin-44",
	"title": "Northern Ecumenical Maternity Home",
	"description": `The formation of the Northern Ecumenical Maternity Home grew out of local efforts in 1961 when the need of a program of assistance to unmarried mothers was recognized as a serious issue. It was run by the Evangelicals`,
  "street": "P.O. Box 955",
	"place": "Sudbury, Ontario",
	"lat": 46.493255,
	"lng": -80.999641
},
{
	"id": "pin-45",
	"title": "Our Lady of Mercy",
	"description": `<div>In 1946, a total of forty babies were in the Home awaiting placement. The Society became concerned about this problem, and, the same year conducted a joint campaign for homes with the Vancouver Children's Aid Society. Pictures of children and the need for homes were published in all place papers, including the B. C. Catholic, and appeals were also made on local radio stations.</br></br>

Miss Plynn, in commenting to the Board on the campaign, said: "We are indebted to the Vancouver newspapers which following our last meeting reported our need for homes for babies. The Vancouver Sun gave us a page on which the babies were shown. This brought a wide response. This publicity is still bearing fruit .... The B. C. Catholic ran a series of articles on the need for homes during the month of May, and this too was very effective.</div>"
`,
  "street": "1050 West 54th Avenue",
	"place": "Vancouver, British Columbia",
	"province": "",
	"lat": 49.221689,
	"lng": -123.129671
},
{
	"id": "pin-46",
	"title": "Pavillon Jette: Foyer Marie-Lucie et Foyer Marguerite",
	"description": `Pavillon Jette: Foyer Marie-Lucie et Foyer Marguerite operated in Montreal by the Roman Catholic Church of Canada`,
  "street": "850, Boulevard Dorchester",
	"place": "Montreal, Quebec",
	"lat": 45.500795,
	"lng": -73.567622
},
{
	"id": "pin-47",
	"title": "Rideau Terrace (in Rockcliffe) ",
	"description": "",
  "street": "Rideau Terrace",
	"place": "Ottawa, Ontario",
	"lat": 43.443965,
	"lng": -75.677829
},
{
	"id": "pin-48",
	"title": "Rosalie Hall",
	"description": `Rosalie Hall has served the Toronto communities for 97 years. In 1914, due to a lack of suitable maternity and infant care of single women and their children in Ontario, the Misericordia Sisters started the St. Mary's Infant Home on Bond Street. To accommodate the growing need for their services, the Sisters then purchased two properties on Jarvis Street. One became the St. Mary's Hospital and the other became a home for young mothers and their babes. The hospital was used for maternity care, but in 1925, was modified to accommodate medical and surgical treatments as well. In July of 1952, the Sisters purchased 28 acres of land at the corner of Lawrence Avenue and McCowan Road. In 1956, both the hospital and the home were moved to Scarborough, now known as The Scarborough Hospital and Rosalie Hall`,
  "street": "3020 Lawrence Avenue East",
	"place": "Scarborough, Ontario",
	"img": "/Final Maternity Home Photos/ROSALIE HALL TORONTO.jpg",
	"lat": 43.755570,
	"lng": -79.248731
},
{
	"id": "pin-49",
	"title": "St. Gerrard's Home",
	"description": "During its operation, the Catholic Family Services sought and found homes in the United States and other parts of Canada for children of unwed mothers. The service to the U.S.A. was discontinued in 1976",
  "street": "69 Pownal Street",
	"place": "Charlottetown, Prince Edward Island",
	"img": "/Final Maternity Home Photos/ST.GERARD'S PEI.jpg",
	"lat": 46.232411,
	"lng": -63.128133
},
{
	"id": "pin-50",
	"title": "St. John’s House",
	"description": `St. John’s was a maternity home operated by The Anglican Church of Canada`,
  "street": "11714 92 Street NorthWest",
	"place": "Edmonton",
	"province": "Alberta",
	"lat": 53.569631,
	"lng": -113.483309
},
{
	"id": "pin-51",
	"title": "St. Martin's Manor",
	"description": "Under its auspices, the Catholic Children’s Aid Society of Hamilton-Wentworth was launched in 1954. In the same year, St. Martin’s Manor, a residence for single pregnant young women was opened. In 1965 the agency changed its name to Catholic Social Services to reflect the growing emphasis on social support and counselling services in place of material assistance, which had become the mandate of publicly funded agencies",
  "street": "500 Mohawk Rd West",
	"place": "Hamilton, Ontario",
	"img": "/Final Maternity Home Photos/ST.MARTIN'S MANOR.jpg",
	"lat": 43.232454,
	"lng": -79.904941
},
{
	"id": "pin-52",
	"title": "St. Mary’s Home",
	"description": "St. Mary’s home was run by the Roman Catholic church the (Sisters of Providence. It was founded in 1933. In its original form, St. Mary’s was one of the “homes for unwed mothers” that existed in the province at a time when pregnant teenagers were hustled out of sight",
  "street": "Daly Avenue",
	"place": "Ottawa, Ontario",
	"img": "/Final Maternity Home Photos/ST. MARY'S HOME OTTAWA.jpg",
	"lat": 45.428548,
	"lng": -75.683039
},
{
	"id": "pin-53",
	"title": "St. Mary’s Infants Home",
	"description": `The Sisters of Misericorde opened The St. Mary’s Infants’ Home in a converted school house for unmarried mothers and their infants. The organization’s first-ever financial statement, produced in August of 1914, indicates total expenses of $739.32 for the year, including office and telephone charges, as well as 50 cents for “two meals for poor women” and 60 cents for women’s galoshes. Asked years later how he managed to pay for these expenses with no operating budget, Fr. Bench noted that he “begged” for donations through his weekly column in the Register, proof, he said, of the scriptural assertion “Seek and ye shall find.”`,
  "street": "550 Jarvis Street",
	"place": "Toronto, Ontario",
	"lat": 43.669504,
	"lng": -79.379788
},
{
	"id": "pin-54",
	"title": "St. Monica House",
	"description": 'St Monica House now named Monica House was run by the Roman Catholic Church. The 20-bed resident has been housing 12-24 year old pregnant woman (until their babies are 3 months old) for the past 45 years. They had the highest number of pregnant teens in the province and the reason for this statistic is unknown.',
  "street": "231 Herbert Street ",
	"place": "Kitchener / Waterloo, Ontario",
	"lat": 43.460680,
	"lng": -80.514674,
	"img": "/Final Maternity Home Photos/ST.MONICA HOUSE WATERLOO.jpg",
},
{
	"id": "pin-55",
	"title": "St. Monica’s Home - Kitchener",
	"description": `St. Monica’s house has operated for 50 years and is still in operation to this day. It was operated by the Roman Catholic Church`,
  "street": "30 Sycamore Place",
	"place": "Kitchener, Ontario",
	"lat": 43.430208,
	"lng": -80.501837
},
{
	"id": "pin-56",
	"title": "Strathcona",
	"description": " Strathcona House was a private maternity hospital.",
  "street": "32 Gothic Ave",
	"place": "Toronto, Ontario",
	"lat": 43.654270,
	"lng": -79.468384
},
{
	"id": "pin-57",
	"title": "Sundale Manor (1960-1976) / Mercy Shelter (1953-1960)",
	"description": `First operated as a children's shelter/orphanage, then became a home for unwed mothers. Sundale Manor, (home for unwed mothers) was established in 1953 by the Roman Catholic Diocese of London and staffed by Misericordia Sisters. Known first as Mercy Shelter until 1960 and then Sundale Manor until closing in 1976`,
  "street": "140 Park Avenue East",
	"place": "Chatham, Ontario",
	"img": "/Final Maternity Home Photos/SUNDALE MANOR.jpg",
	"lat": 42.399402,
	"lng": -82.172880
},
{
	"id": 'pin-58',
	"title": "The Anchorage",
	"description": "The Anchorage was operated by the Salvation Army. In their national Report 1956-48 they wrote: 'the problem of the unmarried mother is an ever-present one, and continues to occupy a large portion of the time of two of our woman workers. Six months ago we expressed the hope that the departure of the troops from Newfoundland would make a difference and there is reason to feel now that this prediction will be borne out, although it is still a bit early to be confident. We are still dealing with many girls who have given birth to their first child, but the more difficult cases are the repeaters. In the first instance we can sometimes persuade the families of the girls to take care of the children when that is the desirable thing to do, and to assist the unmarried mothers to get on their feet again, but where the number of illegitimate children is two or more it is a different matter.'",
	"street": "26 Cook Street",
	"place": "St. John’s, Newfoundland and Labrador",
	"img": "/Final Maternity Home Photos/ANCHORAGE.jpg",
	"lat": 47.564659,
	"lng": -52.717843
},
{
	"id": "pin-59",
	"title": "The Ideal Maternity Home",
	"description": `"<div>The Ideal Maternity Home was operated by William and Lila Young in East Chester, Nova Scotia, between the late 1920's through the 1940's. William was a chiropractor and Lila was a midwife but marketed herself as an obstetrician. The Home began as a place for local married couples to find maternity care, as well as a discreet location for unwed mothers to have their babies. At the time legal adoptions in the U.S. were not permitted across religious lines and with a shortage of available newborns, many American couples traveled to Nova Scotia to adopt from the Ideal Maternity Home. The cost of the adoptions varied but it is believed that some couples paid up to $10,000 for a baby. At times, there were upwards of 100 babies available for adoption. As time went on, the Youngs' practices became more and more corrupt. Babies who were considered 'unadoptable', either because of skin color or health issues, were left to die and buried in the woods behind the Home or dumped into the ocean. The term 'Butterbox Baby' comes from the small mitered pine butterboxes that came from the local dairy and were used as coffins, just the right size for a newborn.﻿
	<br/><br/>
  That is why we call ourselves 'Survivors of the Ideal Maternity Home', we were the lucky ones."
	<br/><br/>
  From https://www.idealmaternityhomesurvivors.com/the-story/﻿</div>
`,
  "street": "3280 Hwy 3",
	"place": "East Chester, Nova Scotia",
	"lat": 44.562929,
	"lng": -64.222206
},
{
	"id": "pin-60",
	"title": "The Little Flower Institute",
	"description": "Little Flower Institute in Bras d’Or, Cape Breton, cared for 159 children, half of whom were deemed 'illegitimate'. It was not licensed as a maternity home",
  "street": "12 Villa Dr",
	"place": "Little Bras D'or, Cape Breton, Nova Scotia",
	"lat": 46.245037,
	"lng": -60.289947
},
{
	"id": "pin-61",
	"title": "The Pineview Home for Unwed Mothers",
	"description": `In 1962 Pineview Home for unwed mothers was completed and administered by the Sisters of Misercodia`,
  "street": "9820 165th Street NorthWest",
	"place": "Edmonton, Alberta",
	"province": "",
	"lat": 53.537391,
	"lng": -113.607298
},
{
	"id": "pin-62",
	"title": "The Providence Creche",
	"description": `<div>A foster home for children was founded by Mrs Isabella Stevens and a host of women from Calgary in 1943. In 1950, The Sisters of Providence then take on responsibility and Providence Creche continues to care for children and to aid unwed mothers. Here is an excerpt of a mother’s experience in this home:
	</br></br>
“Then I was placed into The Providence Creche, a Catholic home for unwed mothers which also housed “orphans.” Here our shame was re-enforced: We were given wedding rings to wear when we went out. We had to use pseudonyms with each other. I had chosen the name Kathy Pruden. I think I chose Kathy after a girl I knew in high-school who seemed respectable. I think I chose Pruden from the word “prude” as if the name might rub off on me and make me more respectable. Most of the girls in this home for unwed mothers were from out of town, and none of us knew each other’s real names. We were in hiding from the world. We were instructed to be secretive with each other. We were outcasts of society.”</br></br>(Source: http://www.originscanada.org/resources/mothers-stories-adoption-across-canada/adoption-calgary-alberta-1960/ )
`,
  "street": "5232 4th Street SouthWest",
	"place": "Calgary, Alberta",
	"img": "/Final Maternity Home Photos/PROVIDENCE CRECHE.jpg",
	"lat": 51.007875,
	"lng": -114.070745
},
{
	"id": "pin-63",
	"title": "United Church Home for Girls",
	"description": `Bernadette explains:
“<div>My parents were so horrified and shamed by my pregnancy that in order to keep my ‘situation’ a secret, they brought all 9 of my siblings who were not married, to Canada for a ‘six-month holiday’. Upon arrival in Vancouver in November 1969, they promptly forced me into a home for unwed mothers where I remained until the day of my child’s birth.
Life in the Burnaby Maternity Home was highly regulated and restrictive with visitors, mail, and telephone calls being censored. I was instructed by my parents, maternity home staff and social worker to use my first name only, prohibited from revealing my last name, sharing any identifying family information, or developing friendships with other mothers. I was obliged to buy a cheap ring resembling a wedding ring and always wear it when outside the maternity home so no one would know my shame. Mothers who expressed the desire to keep their babies were isolated from other mothers.</br></br>
Through indoctrination and constant reinforcement, the messages delivered by my parents, social workers, maternity home workers and church officials constantly undermined my ability to mother my child and emphasised the following: My child was always referred to as ‘the baby’ or ‘it’, never personalized as part of me; I was not fit to mother the baby who deserved better than me; I would be selfish and immature if I kept her; I would forget about ‘it’ and never think about ‘it’ again; this birth would be like having my appendix out, a simple procedure and I would get on with my life as if nothing ever happened; I would have other children and be a real mother someday.</div>”
`,
  "street": "1750 Sussex Avenue",
	"place": "Burnaby, British Columbia",
		"img": "/Final Maternity Home Photos/UNITED CHURCH HOME FOR GIRL.jpg",
	"lat": 49.217093,
	"lng": -123.005563

},
{
	"id": "pin-64",
	"title": "Victor Home for Women",
	"description": `Victor home for Woman was run by the United Church of Canada. Their roots in the community date back to the beginning of the twentieth century, when the Fred Victor Mission opened its doors to women in need and subsequently founded a mission dedicated to supporting unwed, pregnant women, single mothers, and their babies. This mission was eventually incorporated as The Massey Centre for Women in 1989. In a National Post article, Karen Marten, a mother who had been sent to this home when she was 17 in 1969, states that in this home “You just felt like your life was on hold. It was a very lonely and very sad place.”`,
  "street": "1102 Broadview Avenue",
	"place": "East York, Ontario",
			"img": "/Final Maternity Home Photos/Victor Home.jpg",
	"lat": 43.686851,
	"lng": -79.356603
},
{
	"id": "pin-65",
	"title": "Villa Marie-Claire",
	"description": `Villa Marie-Claire (Sisters of Misericordia) in Sherbrooke, Quebec. Was operated by the Roman Catholic Church of Canada.
`,
  "street": "225, Rue Belvedere Nord",
	"place": "Sherbrooke, Quebec",
	"img": "/Final Maternity Home Photos/VILLA MARIE-CLAIRE.jpg",
	"lat": 45.402087,
	"lng": -71.899766
},
{
	"id": "pin-66",
	"title": "Villa Rosa",
	"description": "The Sisters of the Miséricorde, a Roman Catholic group of women founded in 1848 for the purpose of providing spiritual and corporal assistance to poor mothers and girls, arrived in Winnipeg in November 1898. Residents were mostly middle class women sent to complete their pregnancies away from home",
  "street": "784 Wolseley Avenue",
	"place": "Winnipeg, Manitoba",
	"img": "/Final Maternity Home Photos/VILLA ROSA WINNIPEG.jpg",
	"lat": 49.879550,
	"lng": -97.163628
},
{
	"id": "pin-67",
	"title": "Villa St.-Michel",
	"description": "Villa St.Michel was operated by the Roman Catholic Church by the (Sisters of Misericordia) in Montreal",
  "street": "7400 Boulevard Saint-Michel",
	"place": "Montreal, Quebec",
	"img": "/Final Maternity Home Photos/VILLA ST.MICHEL.jpg",
	"lat": 45.559820,
	"lng": -73.601838
},

]
