import React, { Fragment } from "react";
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

const GetInvolved = props => {
  return (
    <Fragment>
      <Container className="InvolvedBlock">
        <div>
          <Row>
            <Col sm="12">
            <h2>Do you have any questions?</h2><h2>Want to help make a change?</h2>
            <br/>
            <h3>Please consider donating to organizations that support mothers, fathers, and adoptees across the country?</h3><br/>
            </Col>

            <Col xs="12"  md="12" lg="6" className="sectionInvolved">
              <div className="country">Canada</div>
              <br/>
              <a href="https://www.originscanada.org/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /> Origins Canada</a>
              <br/>
              <a href="http://adoptioncircles.net/wp/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /> Forget Me Not Family Society</a>
              <br/>
              <a href="https://parentfindersottawa.ca/support-group/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /> Parent Finders Canada</a>
              <br/>
              <a href="https://60sscoopfoundation.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} />Sixties Scoop Survivors Foundation</a>
              <br/>
              <a href="https://www.askaboutreunion.org/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /> Adoption Support Kinship (Toronto)</a>
              <br/>
              <a href="http://www.ccnm-mothers.ca/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /> Canada Council of Natural Mothers</a>
              <br/>
              <div className="country">Québec</div>
              <br/>
              <a href="http://adoptionquebec.ca/ladoption-au-quebec/retrouvailles/rais/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /> Rais Ressource Adoption</a>
              <br/>
              <a href="http://www.lhybride.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /> Hybridé</a>
              <br/>
              <a href="http://www.mouvement-retrouvailles.qc.ca/index.html" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /> Mouvement Retrouvailles</a>
              <br/>
            </Col>
            <Col xs="12"  md="12" lg="6" className="sectionInvolved">
              <div className="country">GLOBAL</div>
              <div className="highlightCountry">
                <div className="">USA</div>
                <br/>
                <a href="https://www.cubirthparents.org/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} />Concerned United Birthparents (CUB) (USA)</a>
                <br/>
                <a href="https://savingoursistersadoption.org/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /> Saving Our Sisters (USA)</a>
                <br/>
                <a href="https://thefamilypreservationproject.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /> The Family Preservation Project (USA)</a>
                <br/>
                <a href="http://www.originsamerica.org/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} />Origins America</a>
                <br/>
                <a href="http://bastards.org/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} />Bastard Nation (USA & Canada)</a>
                <br/>
                <a href="http://babyscoopera.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} />The Baby Scoop Era Research Initiative (USA)</a>
                <br/>
              </div>
              <div className="highlightCountry">
                <div className="">UK</div>
                <br/>
                <a href="https://www.motherandbabyhomes.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} />Mother and Baby Homes (UK)</a>
                <br/>
                <a href="https://movementforanadoptionapology.org/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} />Movement for an Adoption Apology (UK)</a>
                <br/>
              </div>
              <div className="highlightCountry">
                <div className="">AUSTRALIA</div>
                <br/>
                <a href="http://www.originsnsw.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /> Origins (Australia)</a>
                <br/>
                <a href="http://www.originsnsw.com/originsqueensland/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /> Origins (Queensland)</a>
              </div>


            </Col>

          </Row>
        </div>
      </Container>
    </Fragment>
  )
}

export default GetInvolved;
