import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FooterSupport from '../components/FooterSupport.js'
import { faPlayCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
// import ToggleDisplay from 'react-toggle-display';
import Modal from '../components/VideoModal.js';
// import Sound from 'react-sound';
import { TabletView } from 'react-device-detect';
import VideoComponentBackground from '../components/VideoComponentBackground'


const Home = props => {
    const [muted, setMuted] = useState(false)
  const [videoUrl] = useState('/Opening.mp4')
  const [show, setShow] = useState(false)

  const handleHide = () => {
    setShow(false)
  }

  const vidisPlaying = () =>  {
    // preventDefault();
  }

  const onvideoEnd = () =>  {
    // preventDefault();
  }



  useEffect(() => {
  props.newPageLoad()
  }, [])

  useEffect(() => {
    console.log(props)
    setMuted(props.muted) // 2. props changed? do this
  }, [props]) // 1. if props has changed?, no.

  return (

    <div>
      { !props.autoplayWorking && (
        <div className="animate-up"></div>)}
        <img src={'banner-social.jpg'} alt='social-banner' style={{"display": "none"}}/>
        <div className='confineWidth home'>

          <TabletView>
             <div className="blurbTablet">best Viewed on Desktop / Laptop Screens</div>
          </TabletView>

          { props.autoplayWorking && (
           <VideoComponentBackground
             videoUrl={videoUrl} play={true} loop={true}
             controls={false}
             muted={true}
               imageUrl={"/black.jpg"}
               onVideoPlaying={vidisPlaying}
               onVideoEndedCallback={onvideoEnd}
           />

           )
          }

          <div className="homeOverlay">
            <div className="homeLogo"><span>mum's the word</span><em>An interactive experience about a nation of secrets</em>
          </div>

          <Link to="/the-secret" className="start">
            <FontAwesomeIcon icon={faPlayCircle} /> &nbsp;Watch the film
         </Link>
          <Modal muted={muted} vidisPlaying={props.vidisPlaying} vidToBeMuted={props.vidToBeMuted} />

        </div>

        </div>

      <FooterSupport />

    </div>
  )
}

export default Home;
