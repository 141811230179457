
const jsonPrivate = [
  { id: 1, title: "How are adoptees impacted by the silence and secrecy of the adoption mandate?",
    img: "/contributors/daniel.jpg",
    hover: "/contributors/daniel_hover.jpg",
    holding: "/contributors/daniel_screen.jpg",
    preview: "https://vimeo.com/368811627",
    url: "https://vimeo.com/368807436",
    description: "Daniel describes the lifelong impact that not knowing where he came from has made on his life."},
  { id: 2, title: "How did you learn about the pain of your mother's loss?",
    img: "/contributors/lori.jpg",
    hover: "/contributors/lori_hover.jpg",
    holding: "/contributors/lori_screen.jpg",
    preview: "https://vimeo.com/368812757",
    url: "https://vimeo.com/368809859",
    description: "Lori never met her mother, but she learned about her loss in the most unusual way."},
  { id: 3, title: "How does that loss and grief stay with adoptees throughout their life?",
    img: "/contributors/wendy.jpg",
    hover: "/contributors/wendy_hover.jpg",
    holding: "/contributors/wendy_screen.jpg",
    preview: "https://vimeo.com/368813370",
    url: "https://vimeo.com/368810277",
    description: "Wendy describes the loss and grief that adult adoptees describe in her adoption support groups."},
  { id: 4, title: "What supports were in place to help mothers after their loss?",
    img: "/contributors/celine.jpg",
    hover: "/contributors/celine_hover.jpg",
    holding: "/contributors/celine_screen.jpg",
    preview: "https://vimeo.com/368813014",
    url: "https://vimeo.com/368810691",
    description: "Celine describes the lack of supports for mothers who lost their children."},
  { id: 5, title: "What does grief look like for mothers of loss?",
    img: "/contributors/pearl.jpg",
    hover: "/contributors/pearl_hover.jpg",
    holding: "/contributors/pearl_screen.jpg",
    preview: "https://vimeo.com/368813282",
    url: "https://vimeo.com/368811186",
    description: "Pearl describes the profound impact of grief that she has experienced throughout her entire life and how that affected her future relationships."},
  { id: 6, title: "How is adoption a human rights issue?",
    img: "/contributors/colleen_c.jpg",
    hover: "/contributors/colleen_c_hover.jpg",
    holding: "/contributors/colleen_c_screen.jpg",
    preview: "https://vimeo.com/368812888",
    url: "https://vimeo.com/368811360",
    description: "Colleen C. explains how adoptee's loss of identity is a violation of their basic human rights."},
  { id: 7, title: "How has the idealization of adoption distorted our understanding of the history of adoption?",
    img: "/contributors/mary.jpg",
    hover: "/contributors/mary_hover.jpg",
    holding: "/contributors/mary_screen.jpg",
    preview: "https://vimeo.com/368813107",
    url: "https://vimeo.com/368808957",
    description: "Mary describes how the idealization of adoption does not match her experiences as an adoptee."},
  { id: 8, title: "What does grief look like for mothers of loss?",
    img: "/contributors/colleen_r.jpg",
    hover: "/contributors/colleen_r_hover.jpg",
    holding: "/contributors/colleen1_r_screen.jpg",
    preview: "https://vimeo.com/368813639",
    url: "https://vimeo.com/368810903",
    description: "Colleen R. describes the profound impact of grief that she has experienced throughout her entire life."},
  { id: 9, title: "How does the silence impact international adoptees?",
    img: "/contributors/sarah.jpg",
    hover: "/contributors/sarah_hover.jpg",
    holding: "/contributors/sarah_screen.jpg",
    preview: "https://vimeo.com/368812548",
    url: "https://vimeo.com/368808640",
    description: "Sarah describes the impacts of the silence on international adoptees"},
  { id: 10, title: "What supports were in place to help mothers after their loss?",
    img: "/contributors/karen.jpg",
    hover: "/contributors/karen_hover.jpg",
    holding: "/contributors/karen_screen.jpg",
    preview: "https://vimeo.com/368813479",
    url: "https://vimeo.com/368808335",
    description: "Karen describes the lack of supports for mothers who lost their children."},
  { id: 11, title: "How has adoption loss impacted you?",
    img: "/contributors/nancy.jpg",
    hover: "/contributors/nancy_hover.jpg",
    holding: "/contributors/nancy_screen.jpg",
    preview: "https://vimeo.com/368812639",
    url: "https://vimeo.com/368809654",
    description: "Nancy describes the profound loss that she felt when losing her children and having very little supports or advocacy."},
  { id: 12, title: "How did the shame and stigma impact your after the loss of your child?",
    img: "/contributors/suzanne.jpg",
    hover: "/contributors/suzanne_hover.jpg",
    holding: "/contributors/suzanne1_screen.jpg",
    preview: "https://vimeo.com/368812450",
    url: "https://vimeo.com/368808122",
    description: "Suzanne describes the profound stigmatization that she lived with after losing her child.. "},
  { id: 13, title: "What is the similarities between the post-war adoption mandate and the current international adoption system?",
    img: "/contributors/abby.jpg",
    hover: "/contributors/abby_hover.jpg",
    holding: "/contributors/abby_screen.jpg",
    preview:"https://vimeo.com/368812208",
    url: "https://vimeo.com/368807679",
    description: 'Abby describes the similarities between her experiences as an international adoptee and the adoption system of the past.'},
  { id: 14, title: "How has the silence affected society's ability to understand the adoption mandate?",
    img: "/contributors/egg.jpg",
    hover: "/contributors/egg_hover.jpg",
    holding: "/contributors/egg_screen.jpg",
    preview: "https://vimeo.com/368812340",
    url: "https://vimeo.com/368807944",
    description: "Senator Art Eggleton explains how the silence has affected society's ability to understand the adoption mandate."},
  ]

// --------------------------------------

const jsonPublic = [
  { id: 1, title: "How are adoptees impacted by the silence and secrecy of the adoption mandate?",
    img: "/contributors/daniel.jpg",
    hover: "/contributors/daniel_hover.jpg",
    holding: "/contributors/daniel_screen.jpg",
    preview: "https://vimeo.com/368811627",
    url: "https://vimeo.com/368807436",
    description: "Daniel describes the lifelong impact that not knowing where he came from has made on his life."},
  { id: 2, title: "How did you learn about the pain of your mother's loss?",
    img: "/contributors/lori.jpg",
    hover: "/contributors/lori_hover.jpg",
    holding: "/contributors/lori_screen.jpg",
    preview: "https://vimeo.com/368812757",
    url: "https://vimeo.com/368809859",
    description: "Lori never met her mother, but she learned about her loss in the most unusual way."},
  { id: 3, title: "How does that loss and grief stay with adoptees throughout their life?",
    img: "/contributors/wendy.jpg",
    hover: "/contributors/wendy_hover.jpg",
    holding: "/contributors/wendy_screen.jpg",
    preview: "https://vimeo.com/368813370",
    url: "https://vimeo.com/368810277",
    description: "Wendy describes the loss and grief that adult adoptees describe in her adoption support groups."},
  { id: 4, title: "What supports were in place to help mothers after their loss?",
    img: "/contributors/celine.jpg",
    hover: "/contributors/celine_hover.jpg",
    holding: "/contributors/celine_screen.jpg",
    preview: "https://vimeo.com/368813014",
    url: "https://vimeo.com/368810691",
    description: "Celine describes the lack of supports for mothers who lost their children."},
  { id: 5, title: "What does grief look like for mothers of loss?",
    img: "/contributors/pearl.jpg",
    hover: "/contributors/pearl_hover.jpg",
    holding: "/contributors/pearl_screen.jpg",
    preview: "https://vimeo.com/368813282",
    url: "https://vimeo.com/368811186",
    description: "Pearl describes the profound impact of grief that she has experienced throughout her entire life and how that affected her future relationships."},
  { id: 6, title: "How is adoption a human rights issue?",
    img: "/contributors/colleen_c.jpg",
    hover: "/contributors/colleen_c_hover.jpg",
    holding: "/contributors/colleen_c_screen.jpg",
    preview: "https://vimeo.com/368812888",
    url: "https://vimeo.com/368811360",
    description: "Colleen C. explains how adoptee's loss of identity is a violation of their basic human rights."},
  { id: 7, title: "How has the idealization of adoption distorted our understanding of the history of adoption?",
    img: "/contributors/mary.jpg",
    hover: "/contributors/mary_hover.jpg",
    holding: "/contributors/mary_screen.jpg",
    preview: "https://vimeo.com/368813107",
    url: "https://vimeo.com/368808957",
    description: "Mary describes how the idealization of adoption does not match her experiences as an adoptee."},
  { id: 8, title: "What does grief look like for mothers of loss?",
    img: "/contributors/colleen_r.jpg",
    hover: "/contributors/colleen_r_hover.jpg",
    holding: "/contributors/colleen1_r_screen.jpg",
    preview: "https://vimeo.com/368813639",
    url: "https://vimeo.com/368810903",
    description: "Colleen R. describes the profound impact of grief that she has experienced throughout her entire life."},
    { id: 13, title: "What is the similarities between the post-war adoption mandate and the current international adoption system?",
    img: "/contributors/abby.jpg",
    hover: "/contributors/abby_hover.jpg",
    holding: "/contributors/abby_screen.jpg",
    preview:"https://vimeo.com/368812208",
    url: "https://vimeo.com/368807679",
    description: 'Abby describes the similarities between her experiences as an international adoptee and the adoption system of the past.'},
]

function getJson() {
  const mode = process.env.REACT_APP_MODE

  if (mode === "private") {
    return jsonPrivate
  } else {
    return jsonPublic
  }
}

export default getJson
