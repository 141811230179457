import React, { Component } from 'react';
import { Row, Container } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { markers } from "./Markers"
import ToggleDisplay from 'react-toggle-display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes  } from '@fortawesome/free-solid-svg-icons'

import Map from './Map';
// import Spinner from '../sharedComponents/Spinner';

import scrollIntoView from 'scroll-into-view-if-needed'

class AllLocations extends Component {

  constructor(props) {
    super(props);
    this.state = {
      markers: markers,
      center: {
        lat: 46.7879926,
        lng: -67.6970126
      },
      timestamp: Date.now(),
      placeSelected: {},
      rowSelect: [],
      width: 0,
      scrolled: "",
      show: true

    };
    this.selectTableByMarker = this.selectTableByMarker.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);
  }

  handleHide = () => {
    this.setState({
      show: !this.state.show
    });
  }


  // ------

  formatTableActions = (cell, row) => {
    return (
      <div className="copy-title">
      <p>{row.title}</p>
    </div>
    )
  }

  onRowSelected = (row, isSelected) => {
    let rowSelected
    let latLng = {};


    if (row && row.id) {
      rowSelected = [row.id];
      latLng["lat"] = parseFloat(row.lat);
      latLng["lng"] = parseFloat(row.lng);
      latLng["id"] = row.id
    }

    this.setState(() => ({
        rowSelect: rowSelected,
        marker: row,
        placeSelected: latLng

    }))
  }

  selectTableByMarker = (id) => {
    let data = this.state.markers;
    let selected = [];

    data.map((d, index) => {
      if (d.id === id) {
        selected.push(id)
      }
    })


    this.setState({
      rowSelect: selected
    })

    let selectedId = selected.toString()

    if (this.state.scrolled !== selectedId ) {

      const node = document.getElementsByClassName(selectedId)[0]
      scrollIntoView(node, {
        behavior: 'smooth',
        block: 'center',
        boundary: document.getElementsByClassName('listLocations react-bs-container-body')[0]
      });
    }

    this.setState({
      scrolled: selected.toString()
    })
  }

  trClassFormat(row) {
   return `${row.id}`
  }

  filterFunction(cell, row) {
    // just return type for filtering or searching.
    return cell.title;
  }

  formatCommunity(cell, row) {
    return (<div className="copy-place">
    <p>{cell}</p>
  </div>
)
}

  goBack = (e) => {
    e.preventDefault();
    this.goTo("/locations/maps")
  }



  render() {
  let that = this;

  const selectRowProp = {
    mode: 'radio',
    selected: this.state.rowSelect,
    onSelect: this.onRowSelected,
    bgColor: '#C0D5C5', // you should give a bgcolor, otherwise, you can't regonize which row has been selected
    hideSelectColumn: true,  // enable hide selection column.
    clickToSelect: true  // you should enable clickToSelect, otherwise, you can't select column.
  };

  const keyBoardNav = {
  enterToSelect: true
};

      let { markers, marker } = this.state
      let center = this.state.center

      return (
        <div>
          <Container id="map-component" className="normalMap allLocations" fluid>

            <Row>
              <div className="map">
                <Map
                  markers={markers}
                  center={center}
                  toggleMapview={this.props.toggleMapview}
                  placeSelected={this.state.placeSelected}
                  selectTableByMarker={this.selectTableByMarker}
                  setParentState={this.setParentState}
                  userinfo={this.state.userinfo}
                  />
              </div>

              <div className='listLocations animated fadeInLeftBig delay-1s'>
                    <BootstrapTable
                    ref='table'
                    version='4'
                    selectRow={ selectRowProp }
                    data={ markers }
                    keyBoardNav={keyBoardNav}
                    trClassName={ this.trClassFormat }>
                    <TableHeaderColumn ref='title' dataField='title' dataSort={ true }  filter={ { type: 'TextFilter', delay: 1000, placeholder: 'Enter Name...'  } } dataFormat={this.formatTableActions}>Home Name </TableHeaderColumn>
                    <TableHeaderColumn ref='place' dataField='place' dataSort={ true } filter={ { type: 'TextFilter', delay: 1000 } }  dataFormat={this.formatCommunity}>Location</TableHeaderColumn>

                    <TableHeaderColumn dataField='id' className="actions" hidden isKey width='35%'>Action</TableHeaderColumn>
                    </BootstrapTable>
              </div>

              <ToggleDisplay show={this.state.show} className="topInfo animated fadeIn delay-2s">
              <div >
              <FontAwesomeIcon onClick={ () => this.handleHide() } icon={faTimes} />
              <b>There were dozens of church-run and private maternity homes that operated in every province across Canada throughout the twentieth century. Many of these building are still standing today.</b> <br/><br/>Explore this interactive map to understand their history and location.</div>
            </ToggleDisplay>
            </Row>
        </Container>
        </div>
      )

  }
};

export default AllLocations;
