import React, { useState, useEffect, Fragment } from "react";
import ShowStories from '../components/ShowStories'

import getJson from './json/jsonSeparationStories';

const json = getJson()

const SeparationStories = props => {
  const [dataArray] = useState(json)
  const [nextNumber] = useState("Four");
  const [nextName] = useState("The Silence");
  const [nextRoute] = useState("/the-silence");
  const [prevNumber] = useState("Two");
  const [prevName] = useState("Hidden Away");
  const [prevRoute] = useState("./hidden-away");
  const [muted, setMuted] = useState(false)
  const [playing, setPlaying] = useState(false)
  const { isLandscape, isIE, isEdge } = props
  const highlightData = useState({
    preview: 'https://vimeo.com/382434007',
    holding: "/separation1.jpg"
  })


  useEffect(() => {
      setPlaying(props.playing)
    setMuted(props.muted) // 2. props changed? do this
  }, [props]) // 1. if props has changed?, no.

  useEffect(() => {
  props.newPageLoad()
  }, [])

  return (
    <Fragment>
      <ShowStories
      data={dataArray}
      nextName={nextName}
      nextNumber={nextNumber}
      nextRoute={nextRoute}
      prevNumber={prevNumber}
      muted={muted}
      playing={playing}
      newPageLoad={props.newPageLoad}
      prevName={prevName}
      prevRoute={prevRoute}
      vidisPlaying={props.vidisPlaying}
      isLandscape={isLandscape}
      isIE={isIE}
      isEdge={isEdge}
      highlightData={highlightData}
        />
    </Fragment>
  )
}

export default SeparationStories;
