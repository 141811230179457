import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
var date = new Date();

const FooterContainer = styled(Container)`
  position: absolute;
  bottom: 6%;
  left: 0;
  width: 100%;
  // height: 95px;
  .copyRight {
    font-size: 1rem;
    line-height: 1.2rem;
    margin: 1rem auto;
    text-align: center;
  }
  z-index: 1;
  @media (max-width: 767px) {
    top: 77vh;
    height: 150px;
  }
  @media (min-width: 767px) {
    .copyRight {
      font-size: 0.8rem;
      margin: 6% auto 0;
    }
  }
`

const FooterSupport = props => {
  return (
    <FooterContainer fluid>
      <Row>
        <Col sm="12">
        <div className="footerSupporters">
          <Row>
          <Col xs={6} md={3}>
            <img src={'branding/canada-council-white.png'}alt="canada council logo" className="brandingImg"/>
          </Col>
          <Col xs={{size: 12, order: 3 }} md={{size: 6, order: 2 }} className="no-padding">
            <div className="copyRight">© EVENINGS AND WEEKENDS PRODUCTIONS {date.getFullYear()}</div>
          </Col>
          <Col xs={{size: 6, order: 2 }}  md={{size: 3, order: 3}}>
            <img src={'branding/toronto-arts.png'} alt="toronto arts logo" className="brandingImg"/>
          </Col>
            </Row>
          </div>
          </Col>

      </Row>
      </FooterContainer>
  )
}

export default FooterSupport
