import React, { useState, useEffect, Fragment } from "react";
import ShowStories from '../components/ShowStories'

import getJson from './json/jsonHiddenAwayStories';

const json = getJson()

const HiddenAwayStories = props => {
  const [dataArray] = useState(json)
  const [nextNumber] = useState("Three");
  const [nextName] = useState("Separation");
  const [nextRoute] = useState("/separation");
  const [prevNumber] = useState("One");
  const [prevName] = useState("The Secret");
  const [prevRoute] = useState("./the-secret");
  const [muted, setMuted] = useState(false)
  const [playing, setPlaying] = useState(false)
  const { isLandscape, isIE, isEdge } = props
  const highlightData = useState({
    preview: 'https://vimeo.com/382433912',
    holding: "/hiddenaway1.jpg"
  })

  useEffect(() => {
      setPlaying(props.playing)
    setMuted(props.muted) // 2. props changed? do this
  }, [props]) // 1. if props has changed?, no.

  useEffect(() => {
  props.newPageLoad()
  }, [])

  return (
    <Fragment>
      <ShowStories
      data={dataArray}
      nextName={nextName}
      nextNumber={nextNumber}
      nextRoute={nextRoute}
      prevNumber={prevNumber}
      muted={muted}
      playing={playing}
      newPageLoad={props.newPageLoad}
      prevName={prevName}
      prevRoute={prevRoute}
      vidisPlaying={props.vidisPlaying}
      isLandscape={isLandscape}
      isIE={isIE}
      isEdge={isEdge}
      highlightData={highlightData}
        />
    </Fragment>
  )
  }

export default HiddenAwayStories;
